import { fetchQuery, fetchWrapper } from '@helpers/functions'

export async function getCertificates(ctx = null, queryParams = {}, options = {}) {
	// TODO: Make normal sort and filtering
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/collectibles' + fetchQuery(queryParams), ctx, options)
}

export async function getCertificate(slug, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/collectibles/' + slug, ctx, options)
}

export async function getTemplateCertificate(slug, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/admin/collectibles/' + slug, ctx, options)
}

export async function getBrandsRelatedCertificates(brandSlugs, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/collectibles' + fetchQuery({ brand: brandSlugs.join(',') }), ctx, options)
}

export async function getCertificateArns(slug, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/collectibles/' + slug + '/arns', ctx, options)
}

export async function getRelatedCertificatesFromCollection(id, collectionID, ctx = null, options = {}) {
	// TODO: Пробрасывать лимит руками
	// TODO: Исключать текущий токен

	return await fetchWrapper(
		process.env.NEXT_PUBLIC_API_URL +
		'/api/collectibles' +
		fetchQuery({
			certificate_type: 'brand,carbon',
			collection_id: collectionID,
			limit: 4,
		}),
		ctx,
		options
	)
}
