import {UnitsLocale} from '@locales/en'
import {declension} from '@helpers/functions'
import React, {useState} from 'react'
import styled from 'styled-components'
import {Avatar, Image} from '@ui/Blocks'
import {Link, Icon} from '@ui/Modules'
import {motion, AnimatePresence, useAnimation} from 'framer-motion'

const RewardListItem = ({data = {}, onLinkClick, className = '', brandColor, ...props}) => {
	const [showProject, setShowProject] = useState(false)
	const [innerSlideVisible, setInnerSlideVisible] = useState(false)
	const howGetControls = useAnimation()

	const fadeOutHowGet = async () => {
		await howGetControls.start({
			opacity: 0,
			transition: {duration: 0.3},
		})
		setInnerSlideVisible(false)
	}

	const variants = {
		initial: {
			x: '100%',
			y: 0,
		},
		in: {
			x: 0,
			y: 0,
		},
		out: {
			x: '-100%',
			y: 0,
		},
	}

	const innerSlideVariants = {
		initial: {x: '100%', opacity: 1},
		in: {x: 0, opacity: 1},
		out: {x: '-100%', opacity: 0},
	}

	return (
		<>
			<div
				style={{background: brandColor}}
				className="inter-medium tw-cursor tw-relative tw-top-[40px] tw-flex tw-max-h-[430px] tw-min-h-[430px] tw-min-w-[260px] tw-max-w-[260px] tw-select-none tw-overflow-hidden tw-rounded-[21.2px] tw-py-[7px] tw-px-[7px] lg:tw-sticky lg:tw-max-h-[546px] lg:tw-min-h-[546px] lg:tw-min-w-[327px] lg:tw-max-w-[327px]"
				{...props}
			>
				<div
					style={{
						background: 'linear-gradient(162.8deg, rgba(255, 255, 255, 0.15) 1.05%, rgba(255, 255, 255, 0.1) 50.59%, rgba(255, 255, 255, 0.2) 99.15%)',
					}}
					className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full"
				/>
				<div
					style={{border: `2.5px solid ${brandColor}`, background: brandColor}}
					className="tw-relative tw-flex tw-h-auto tw-w-full tw-flex-col tw-overflow-hidden tw-rounded-[15px] tw-text-white"
				>
					<AnimatePresence>
						{!showProject ? (
							<motion.div
								key="rewards"
								initial="initial"
								animate="in"
								exit="out"
								variants={variants}
								transition={{type: 'tween', duration: 0.3}}
								className="tw-flex tw-h-fit tw-w-full tw-min-w-[245px] tw-max-w-[245px] tw-flex-col tw-items-start lg:tw-min-w-[310px] lg:tw-max-w-[310px]"
							>
								<div style={{borderBottom: `2px solid rgba(255, 255, 255, 0.15`}} className="tw-flex tw-w-full tw-justify-center tw-py-[10px] tw-text-center">
									<h3 className="tw-text-[14px] tw-font-[600] tw-text-white lg:tw-text-[18px]">Rewards</h3>
								</div>
								<div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-px-[12px] tw-pt-[11px] lg:tw-px-[18px]">
									<h3 className="tw-mb-[4px] tw-text-[12px] tw-font-[600] tw-text-white lg:tw-text-[14px]">Rewards</h3>
									<div style={{backgroundColor: `#FFFFFF20`}} className="tw-flex tw-h-fit tw-max-h-[73px] tw-min-h-[73px] tw-w-full tw-flex-col tw-overflow-hidden tw-rounded-[10px]">
										<div className="tw-flex tw-h-full tw-w-full tw-flex-row tw-items-center tw-gap-[10px] tw-overflow-hidden">
											<div
												style={{backgroundColor: `#FFFFFF40`}}
												className="tw-flex tw-h-full tw-max-h-[73px] tw-w-full tw-min-w-[65px] tw-max-w-[65px] tw-items-center tw-justify-center"
											>
												{data['image_links']?.['h200'] && (
													<Image
														srcset={{
															'2x': data['image_links']?.['h400'] ? data['image_links']['h400'] : '',
															'1x': data['image_links']['h200'],
														}}
														src={data['image_links']['h200']}
														className="tw-flex tw-h-full tw-max-h-[50px] tw-min-h-[50px] tw-w-auto tw-min-w-[50px] tw-max-w-[50px] tw-select-none tw-rounded-full tw-object-cover tw-drop-shadow-xl"
														alt={data['name']}
													/>
												)}
											</div>
											<div className="tw-flex tw-max-w-[140px] tw-flex-col tw-gap-[2px] lg:tw-max-w-[200px]">
												<div className="tw-flex tw-w-full">
													<h3 className="tw-truncate tw-whitespace-nowrap tw-text-[10px] tw-leading-[1.1] tw-text-[#ffffff] lg:tw-text-[14px]">{data['name']}</h3>
												</div>
												<div className="tw-mt-[2px] tw-whitespace-nowrap tw-text-[8px] tw-font-[400] tw-leading-[1.25] tw-text-[#ffffff] lg:tw-text-[11px]">
													<span className="tw-text-[#FFFFFF80]">Ends at project completion</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</motion.div>
						) : (
							<motion.div
								key="project"
								initial="initial"
								animate="in"
								exit="out"
								variants={variants}
								transition={{type: 'tween', duration: 0.3}}
								className="tw-absolute tw-top-0 tw-left-0 tw-flex tw-h-fit tw-w-full tw-min-w-[240px] tw-max-w-[240px] tw-flex-col tw-items-start lg:tw-min-w-[310px] lg:tw-max-w-[310px]"
							>
								<div style={{background: brandColor}} className="tw-z-10 tw-flex tw-h-full tw-w-full tw-flex-col">
									<div className="tw-relative tw-flex tw-flex-col tw-items-start tw-justify-between tw-gap-y-[20px] tw-px-[20px] tw-pt-[20px]">
										<div className={'tw-absolute tw-inset-0 tw-bottom-[40px] tw-z-10 tw-flex tw-max-h-[56px] tw-min-h-[56px] tw-items-center tw-overflow-hidden tw-bg-surface-2'}>
											<img alt="" src={data['image_links']['desktop_x2']} className="tw-h-full tw-max-h-[56px] tw-w-full tw-object-cover" />
											{data['banner_links']?.['desktop_x2'] && (
												<Image
													srcset={{
														'2x': data['list_image_links']?.['h500']
															? data['list_image_links']['h500']
															: data['banner_links']?.['desktop_x2']
															? data['banner_links']['desktop_x2']
															: '',
														'1x': data['list_image_links']?.['h250']
															? data['list_image_links']['h250']
															: data['banner_links']?.['desktop_x1']
															? data['banner_links']['desktop_x1']
															: '',
													}}
													src={data['banner_links']['desktop_x2']}
													className="tw-h-full tw-min-w-full tw-flex-shrink-0 tw-flex-grow-0 tw-select-none tw-object-cover"
													alt={data['name']}
												/>
											)}
										</div>
										<div className="tw-relative tw-flex tw-w-full tw-justify-center">
											{innerSlideVisible ? (
												<>
													{' '}
													<button
														onClick={fadeOutHowGet}
														className={`tw-absolute tw-top-[30%] tw-left-0 tw-z-20 tw-flex tw-max-h-[31px] tw-min-h-[31px] tw-min-w-[31px] tw-max-w-[31px] tw-items-center tw-justify-center tw-rounded-full tw-bg-white`}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
															<path
																d="M10.3821 16.7379C10.3772 16.5292 10.29 16.3308 10.1394 16.1861L2.78565 8.83234L10.1395 1.47854C10.2153 1.4048 10.2755 1.31661 10.3167 1.21918C10.3578 1.12176 10.379 1.01707 10.379 0.911318C10.379 0.753896 10.332 0.600064 10.2441 0.469487C10.1561 0.33891 10.0313 0.237522 9.88541 0.178285C9.73955 0.119049 9.57935 0.104655 9.42528 0.136944C9.2712 0.169234 9.13026 0.246739 9.02046 0.359551L1.10717 8.27284C0.958823 8.42125 0.875487 8.6225 0.875487 8.83234C0.875487 9.04217 0.958823 9.24342 1.10717 9.39183L9.02046 17.3051C9.13097 17.42 9.27362 17.4989 9.42971 17.5314C9.5858 17.5639 9.74807 17.5484 9.89524 17.4871C10.0424 17.4258 10.1676 17.3215 10.2545 17.1878C10.3414 17.0541 10.3859 16.8973 10.3821 16.7379Z"
																fill="black"
															/>
														</svg>
													</button>
												</>
											) : (
												<>
													<button
														onClick={() => setShowProject(!showProject)}
														className={`tw-absolute tw-top-[30%] tw-left-0 tw-z-20 tw-flex tw-max-h-[31px] tw-min-h-[31px] tw-min-w-[31px] tw-max-w-[31px] tw-items-center tw-justify-center tw-rounded-full tw-bg-white`}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
															<path
																d="M10.3821 16.7379C10.3772 16.5292 10.29 16.3308 10.1394 16.1861L2.78565 8.83234L10.1395 1.47854C10.2153 1.4048 10.2755 1.31661 10.3167 1.21918C10.3578 1.12176 10.379 1.01707 10.379 0.911318C10.379 0.753896 10.332 0.600064 10.2441 0.469487C10.1561 0.33891 10.0313 0.237522 9.88541 0.178285C9.73955 0.119049 9.57935 0.104655 9.42528 0.136944C9.2712 0.169234 9.13026 0.246739 9.02046 0.359551L1.10717 8.27284C0.958823 8.42125 0.875487 8.6225 0.875487 8.83234C0.875487 9.04217 0.958823 9.24342 1.10717 9.39183L9.02046 17.3051C9.13097 17.42 9.27362 17.4989 9.42971 17.5314C9.5858 17.5639 9.74807 17.5484 9.89524 17.4871C10.0424 17.4258 10.1676 17.3215 10.2545 17.1878C10.3414 17.0541 10.3859 16.8973 10.3821 16.7379Z"
																fill="black"
															/>
														</svg>
													</button>
												</>
											)}
											<Avatar
												srcset={{
													'2x': data['partner']?.['avatar_links']?.['160x160']
														? data['partner']?.['avatar_links']['160x160']
														: '/assets/images/pages/users/avatars/160x160.jpg',
													'1x': data['partner']?.['avatar_links']?.['96x96'] ? data['partner']?.['avatar_links']['96x96'] : '/assets/images/pages/users/avatars/96x96.jpg',
												}}
												src={data['partner']?.['avatar_links']?.['96x96'] ? data['partner']?.['avatar_links']['96x96'] : '/assets/images/pages/users/avatars/96x96.jpg'}
												className="ui-avatar-primary tw-relative tw-z-20 tw-h-[67px] tw-max-h-[67px] tw-w-[67px] tw-max-w-[67px]"
												alt={data['name']}
											/>
										</div>
									</div>
									<AnimatePresence>
										{innerSlideVisible ? (
											<motion.div
												key="innerDetails"
												initial="initial"
												animate="in"
												exit="out"
												variants={innerSlideVariants}
												transition={{type: 'tween', duration: 0.3}}
												className="tw-absolute tw-top-[110px] tw-left-0 tw-flex tw-h-fit tw-w-full tw-min-w-[240px] tw-max-w-[240px] tw-flex-col tw-items-start tw-px-[20px] lg:tw-min-w-[310px] lg:tw-max-w-[310px]"
											>
												<motion.div initial={{opacity: 1}} animate={howGetControls} exit={{opacity: 0, transition: {duration: 0.3}}}>
													<div dangerouslySetInnerHTML={{__html: data['how_get']}} className="tw-white tw-text-[11px] tw-font-[400] tw-leading-[1.3]" />
												</motion.div>
											</motion.div>
										) : (
											<>
												<div className="tw-mt-[12px] tw-flex tw-flex-col tw-px-[10px] lg:tw-mt-[20px] lg:tw-px-[17px]">
													<div className="tw-font-bold">
														<h3 className="tw-mb-[8px] tw-text-[14px] tw-font-[600] tw-leading-[1.2] tw-text-white lg:tw-text-[18px]">{data['name']}</h3>
													</div>
												</div>
												<div className="tw-flex tw-max-h-[233px] lg:tw-max-h-[335px] tw-flex-col tw-gap-y-[6px] tw-overflow-hidden tw-px-[10px] lg:tw-px-[19px]">
													<div className={`tw-flex ${data['qr_image_links']['1200x1200'] ? 'tw-max-h-[335px]' : 'tw-max-h-fit'} tw-w-full tw-flex-row tw-justify-between`}>
														<p className="tw-white tw-text-[9px] tw-font-[400] tw-leading-[1.3] lg:tw-text-[11px]">{data['description']}</p>
														<div />
													</div>
													{data['qr_image_links']['1200x1200'] ? (
														<>
															<div className="tw-mt-[6px] tw-flex tw-flex-col tw-gap-[6px] lg:tw-mt-[14px]">
																<div>
																	<h3 className="tw-white tw-text-[14px] tw-leading-[1.3]">Your code</h3>
																</div>

																<div className="tw-flex tw-max-h-[100px] tw-min-h-[100px] tw-w-full tw-items-center tw-justify-center tw-rounded-[10px] tw-bg-[#ffffff] tw-bg-opacity-[0.125]">
																	<div className="tw-flex tw-h-full tw-max-h-[60px] tw-w-full tw-max-w-[60px]">
																		<img alt="" src={data['qr_image_links']['1200x1200']} className="tw-w-fulltw-object-cover tw-flex tw-h-full" />
																	</div>
																</div>
															</div>
														</>
													) : (
														<></>
													)}
													{data['qr_image_links']['1200x1200'] ? (
														<div className="tw-flex tw-flex-col">
															<button
																onClick={() => setInnerSlideVisible(true)}
																className="tw-mt-[6px] tw-flex tw-flex-row tw-justify-between tw-border-y-2 tw-border-y-[#FFFFFF] tw-border-opacity-20 tw-py-[14px]"
															>
																<div className="tw-text-[11px] tw-font-[400] tw-leading-[1.2] tw-text-white">How to redeem</div>
																<div>
																	<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
																		<path
																			d="M0.96303 1.37294C0.966144 1.50468 1.02119 1.62985 1.11617 1.72118L5.75734 6.36234L1.11617 11.0035C1.06833 11.05 1.0303 11.1057 1.00433 11.1672C0.978366 11.2287 0.964984 11.2947 0.964981 11.3615C0.965007 11.4608 0.994662 11.5579 1.05016 11.6403C1.10565 11.7228 1.18446 11.7867 1.27651 11.8241C1.36856 11.8615 1.46967 11.8706 1.56691 11.8502C1.66415 11.8298 1.7531 11.7809 1.8224 11.7097L6.81667 6.71545C6.9103 6.62179 6.96289 6.49478 6.96289 6.36234C6.96289 6.22991 6.9103 6.1029 6.81667 6.00923L1.8224 1.01496C1.75265 0.942427 1.66262 0.892653 1.56411 0.872156C1.46559 0.85166 1.36319 0.861396 1.2703 0.90009C1.17741 0.938785 1.09838 1.00463 1.04355 1.08901C0.988721 1.17338 0.960656 1.27235 0.96303 1.37294Z"
																			fill="white"
																		/>
																	</svg>
																</div>
															</button>
															<a
																target="_blank"
																rel="noreferrer"
																href={data['tc_link']}
																className="tw-mt-[6px] tw-flex tw-flex-row tw-justify-between tw-border-opacity-20 tw-py-[14px]"
															>
																<div className="tw-text-[11px] tw-font-[400] tw-leading-[1.2] tw-text-white">Terms & Conditions</div>
																<div>
																	<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
																		<path
																			d="M0.96303 1.37294C0.966144 1.50468 1.02119 1.62985 1.11617 1.72118L5.75734 6.36234L1.11617 11.0035C1.06833 11.05 1.0303 11.1057 1.00433 11.1672C0.978366 11.2287 0.964984 11.2947 0.964981 11.3615C0.965007 11.4608 0.994662 11.5579 1.05016 11.6403C1.10565 11.7228 1.18446 11.7867 1.27651 11.8241C1.36856 11.8615 1.46967 11.8706 1.56691 11.8502C1.66415 11.8298 1.7531 11.7809 1.8224 11.7097L6.81667 6.71545C6.9103 6.62179 6.96289 6.49478 6.96289 6.36234C6.96289 6.22991 6.9103 6.1029 6.81667 6.00923L1.8224 1.01496C1.75265 0.942427 1.66262 0.892653 1.56411 0.872156C1.46559 0.85166 1.36319 0.861396 1.2703 0.90009C1.17741 0.938785 1.09838 1.00463 1.04355 1.08901C0.988721 1.17338 0.960656 1.27235 0.96303 1.37294Z"
																			fill="white"
																		/>
																	</svg>
																</div>
															</a>
														</div>
													) : (
														<>
															<a
																target="_blank"
																rel="noreferrer"
																href={data['tc_link']}
																className="tw-mt-[6px] tw-flex tw-flex-row tw-justify-between tw-border-y-2 tw-border-y-[#FFFFFF] tw-border-opacity-20 tw-py-[14px]"
															>
																<div className="tw-text-[11px] tw-font-[400] tw-leading-[1.2] tw-text-white">Terms & Conditions</div>
																<div>
																	<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
																		<path
																			d="M0.96303 1.37294C0.966144 1.50468 1.02119 1.62985 1.11617 1.72118L5.75734 6.36234L1.11617 11.0035C1.06833 11.05 1.0303 11.1057 1.00433 11.1672C0.978366 11.2287 0.964984 11.2947 0.964981 11.3615C0.965007 11.4608 0.994662 11.5579 1.05016 11.6403C1.10565 11.7228 1.18446 11.7867 1.27651 11.8241C1.36856 11.8615 1.46967 11.8706 1.56691 11.8502C1.66415 11.8298 1.7531 11.7809 1.8224 11.7097L6.81667 6.71545C6.9103 6.62179 6.96289 6.49478 6.96289 6.36234C6.96289 6.22991 6.9103 6.1029 6.81667 6.00923L1.8224 1.01496C1.75265 0.942427 1.66262 0.892653 1.56411 0.872156C1.46559 0.85166 1.36319 0.861396 1.2703 0.90009C1.17741 0.938785 1.09838 1.00463 1.04355 1.08901C0.988721 1.17338 0.960656 1.27235 0.96303 1.37294Z"
																			fill="white"
																		/>
																	</svg>
																</div>
															</a>
														</>
													)}
												</div>
											</>
										)}
									</AnimatePresence>
								</div>
							</motion.div>
						)}
					</AnimatePresence>

					<div className="tw-absolute tw-bottom-0 tw-mt-auto tw-flex tw-h-fit tw-w-full tw-flex-row tw-justify-between tw-bg-[#0000002a] tw-px-[20px] tw-pt-[16px] tw-pb-[12.5px]">
						<div></div>
						<div className="tw-flex tw-flex-row tw-items-center tw-gap-[15px]">
							<div className="tw-flex tw-h-full tw-w-fit tw-items-center">
								<button
									onClick={() => setShowProject(!showProject)}
									disabled={showProject}
									className={`w-fit inter-bold tw-min-h-[26px] tw-min-w-[122px] tw-items-center tw-rounded-full tw-bg-white tw-px-[4px] tw-py-[6px] tw-text-center tw-text-[11.5px] tw-leading-[1.2] tw-text-[#2D2D2D] tw-transition-all ${
										showProject ? 'tw-cursor-not-allowed !tw-opacity-80 hover:tw-text-[#2D2D2D]' : '!tw-opacity-100'
									}`}
								>
									Reward details
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default RewardListItem