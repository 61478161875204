import {useRouter} from 'next/router'
import {memo, useEffect} from 'react'

import {amplitudeTrackEvent} from '@hooks/useAmplitude'

const AppUseAmplitude = () => {
	const router = useRouter()

	const trackEvent = () => {
		if (typeof window !== 'undefined') {
			amplitudeTrackEvent('PAGE_VIEW', {page_path: window.location.href})
		}
	}

	useEffect(() => {
		if (router) {
			router.events.on('routeChangeComplete', trackEvent)

			return () => {
				router.events.off('routeChangeComplete', trackEvent)
			}
		}
	}, [router])

	useEffect(() => {
		trackEvent()
	}, [])
}

export default memo(AppUseAmplitude)
