import {useState, useEffect, Fragment} from 'react'
import CertificateListItem from '@chunks/certificates/ListItemBig'
import BrandListItem from '@chunks/brands/ListItemBig'
import ProjectListItem from '@chunks/projects/ListItemBig'
import RegistryListItem from '@chunks/registries/ListItemBig'
import RegistryItems from './RegistryItems'
import {useCardRelatedRecords} from '@hooks/useCardRelatedRecords'
import {useBrandRelatedCertificates} from '@hooks/useBrandRelatedCertificates'
import {useKeenSlider} from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import {motion} from 'framer-motion'
import RewardsListItem from '@chunks/rewards/ListItem'

const CircularCarousel = ({children}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [sliderRef, instanceRef] = useKeenSlider({
		loop: true,
		slides: {perView: 'auto', spacing: 50},
		breakpoints: {
			'(max-width: 1280px)': {
				slides: {perView: 'auto', spacing: 60},
			},
		},
		centered: true,
		slideChanged(slider) {
			updateSlidesTilt(slider)
			setActiveIndex(slider.track.details.rel)
		},
		created(slider) {
			updateSlidesTilt(slider)
			setLoaded(true)
		},
		move(slider, e) {
			incrementalUpdate(slider)
		},
	})

	const incrementalUpdate = (slider) => {
		const dragProgress = slider.track.details.dragProgress || 0

		const rotationDegree = dragProgress * 360

		if (sliderRef.current) {
			sliderRef.current.style.transform = `rotate(${rotationDegree}deg)`
		}
	}

	const updateSlidesTilt = (slider) => {
		const totalSlides = slider.slides.length
		const absoluteIndex = slider.track.details.abs
		const centerIndex = absoluteIndex % totalSlides

		slider.slides.forEach((slide, i) => {
			const img = slide.querySelector('#card-viewer')
			if (img) {
				let rotationZ, scale, x, y, opacity

				let relativePosition = i - centerIndex
				if (relativePosition < -totalSlides / 2) {
					relativePosition += totalSlides
				} else if (relativePosition > totalSlides / 2) {
					relativePosition -= totalSlides
				}

				if (relativePosition === 1) {
					// Centered
					rotationZ = 0
					scale = 1.08
					x = 0
					y = -10
					opacity = 1
					blur = 0
				} else if (relativePosition === 0) {
					// Left
					rotationZ = -10
					scale = 0.95
					x = 0
					y = 40
					opacity = 1
					blur = '2px'
				} else if (relativePosition === 2) {
					// Right
					rotationZ = 10
					scale = 0.95
					x = 0
					y = 40
					opacity = 1
					blur = '2px'
				} else if (relativePosition === -1) {
					rotationZ = -20
					scale = 0.95
					x = 0
					y = 150
					opacity = 1
					blur = '2px'
				} else if (relativePosition === -2) {
					rotationZ = -25
					scale = 0.95
					x = 10
					y = 310
					opacity = 1
					blur = '2px'
				} else if (relativePosition === 4) {
					rotationZ = 25
					scale = 0.95
					x = -10
					y = 310
					opacity = 1
					blur = '2px'
				} else if (relativePosition === -4) {
					rotationZ = -25
					scale = 0.95
					x = -10
					y = 220
					opacity = 1
					blur = '2px'
				} else {
					rotationZ = 20
					scale = 0.95
					x = 0
					y = 150
					opacity = 1
					blur = '2px'
				}

				img.style.setProperty('--_rotationZ', `${rotationZ}deg`)
				img.style.setProperty('--_scale', scale)
				img.style.setProperty('--_x', `${x}px`)
				img.style.setProperty('--_y', `${y}px`)
				img.style.setProperty('--_opacity', opacity)
				img.style.setProperty('--_blur', blur)
			}
		})
	}

	return (
		<div>
			<div
				ref={sliderRef}
				className="keen-slider tw-mx-auto tw-ml-[22%] tw-h-full !tw-max-h-[560px] tw-w-full tw-items-center !tw-overflow-visible xl:tw-ml-0 xl:tw-pl-[260px]"
				style={{maxWidth: 1600}}
			>
				{children}
			</div>
		</div>
	)
}

export function CardViewerSocial({slug, onClose}) {
	const {isLoading, card, registries, rewards, sponsors, brand, projects} = useCardRelatedRecords(slug)
	const {addBrands} = useBrandRelatedCertificates()
	const brandColor = card?.style_properties?.['background-color'] ?? '#3a3a3a'

	useEffect(() => {
		if (isLoading) return

		addBrands([brand])
	}, [addBrands, brand, isLoading])

	if (isLoading) return <></>

	const handleLinkClick = () => {
		onClose?.(null)
	}

	const renderSlideItem = (data, Component, index) => (
		<div key={data.id} className="keen-slider__slide card-stack-item tw-h-fit tw-max-h-[560px] tw-min-w-[260px] tw-max-w-[260px] !tw-overflow-visible lg:tw-min-w-[327px] lg:tw-max-w-[327px]">
			<div id={`card-viewer`}>
				<Component data={data} brandColor={brandColor} onLinkClick={handleLinkClick} />
			</div>
		</div>
	)

	return (
		<div className="tw-relative tw-z-[999999] tw-mx-auto tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-pt-[10px]">
			<div className="tw-absolute tw-left-0 tw-top-[-30px] tw-flex tw-w-full tw-justify-center tw-text-center tw-text-white lg:tw-top-[-70px]">
				<h3>{brand.name}</h3>
			</div>
			<motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.3, duration: 0.5}} className="tw-z-20 tw-flex tw-w-full tw-justify-center">
				<CircularCarousel>
					{renderSlideItem(brand, BrandListItem, {brandColor})}
					{renderSlideItem(card, CertificateListItem)}
					{rewards.map((rewards, idx) => renderSlideItem(rewards, RewardsListItem, idx, {brandColor}))}
					{projects.map((project, idx) => renderSlideItem(project, ProjectListItem, idx, {brandColor}))}
					<RegistryItems registries={registries} brandColor={brandColor} handleLinkClick={handleLinkClick} />
					{renderSlideItem(brand, BrandListItem, {brandColor})}
					{renderSlideItem(card, CertificateListItem)}
					{rewards.map((rewards, idx) => renderSlideItem(rewards, RewardsListItem, idx, {brandColor}))}
					{projects.map((project, idx) => renderSlideItem(project, ProjectListItem, idx, {brandColor}))}
					<RegistryItems registries={registries} brandColor={brandColor} handleLinkClick={handleLinkClick} />
					{renderSlideItem(brand, BrandListItem, {brandColor})}
					{renderSlideItem(card, CertificateListItem)}
					{rewards.map((rewards, idx) => renderSlideItem(rewards, RewardsListItem, idx, {brandColor}))}
					{projects.map((project, idx) => renderSlideItem(project, ProjectListItem, idx, {brandColor}))}
					<RegistryItems registries={registries} brandColor={brandColor} handleLinkClick={handleLinkClick} />
				</CircularCarousel>
			</motion.div>
		</div>
	)
}
