import {useEffect} from 'react'

import {useHydrateAtoms} from 'jotai/utils'
import {AppState} from '@states/App'
import {UserState} from '@states/User'
import {NotificationsState} from '@states/Notifications'

import {isMobile} from '@hooks/useDevice'

const AppHydration = ({cookies = {}, userAgent = null}) => {
	const device = isMobile(userAgent).any

	useHydrateAtoms([
		[AppState, {
			'init': false,
			'loading': false,
		}],
		[UserState, cookies?.['user.data'] ? JSON.parse(decodeURIComponent(cookies['user.data'])) : null],
		[NotificationsState, {
			certificates: cookies['user.favorite.certificates'] ? JSON.parse(cookies['user.favorite.certificates']) : [],
		}]
	])
	
	useEffect(() => {
        if (typeof document !== 'undefined') {
			//TODO: временный костыль
            document.querySelector('html').setAttribute("data-device", device ? "mobile" : "desktop")
        }
	}, [device])

	return null
}

export default AppHydration
