import {useState, useRef} from 'react'
import ReactCrop from 'react-image-crop'

const Cropper = ({src, onCrop = () => {}, originalFile = {name: 'cropped.jpg', type: 'image/jpeg'}, fullWidth = false, ...props}) => {
	const [crop, setCrop] = useState()
	const [cropPreview, setCropPreview] = useState()
	const [completedCrop, setCompletedCrop] = useState()
	const [originalImage, setOriginalImage] = useState()
	const [file, setFile] = useState()
	const [aspect, setAspect] = useState(null)

	const previewRef = useRef()
	const imgRef = useRef()

	const generatePreview = async (image, canvas, crop, scale = 1) => {
		const ctx = canvas.getContext('2d')

		if (!ctx) {
			throw new Error('No 2d context')
		}

		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const pixelRatio = window.devicePixelRatio

		canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
		canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

		ctx.scale(pixelRatio, pixelRatio)

		const cropX = crop.x * scaleX
		const cropY = crop.y * scaleY

		ctx.save()

		ctx.translate(-cropX, -cropY)

		ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)

		ctx.restore()
	}

	const completeCropHandler = async (crop) => {
		setCompletedCrop(crop)
		if (crop?.width && crop.height && imgRef?.current && previewRef?.current) {
			await generatePreview(imgRef.current, previewRef.current, crop)
			await canvasToFile()
		}
	}

	const canvasToFile = async () => {
		previewRef.current.toBlob(
			(blob) => {
				if (!blob) {
					console.error('Canvas is empty')
					return
				}
				blob.name = originalFile['name']
				const file = new File([blob], originalFile['name'], {type: originalFile['type']})
				const dT = new DataTransfer()
				dT.items.add(file)
				onCrop(dT.files[0])
			},
			originalFile['type'],
			1
		)
	}

	const imageOnLoadHandler = (e) => {
		const {clientHeight, clientWidth} = e.target

		setCrop({
			width: clientWidth < props['minWidth'] || fullWidth ? clientWidth : props['minWidth'],
			height: clientHeight < props['minHeight'] ? clientHeight : props['minHeight'],
			unit: 'px',
		})
	}

	return (
		<>
			<ReactCrop crop={crop} onChange={(c) => setCrop(c)} onComplete={completeCropHandler} aspect={aspect} {...props}>
				<img src={src} ref={imgRef} alt="" className="tw-w-full" onLoad={imageOnLoadHandler} />
			</ReactCrop>

			<canvas
				ref={previewRef}
				style={{
					display: 'none',
					objectFit: 'contain',
					width: completedCrop?.width,
					height: completedCrop?.height,
				}}
			/>
		</>
	)
}

export {Cropper}
