import {UnitsLocale} from '@locales/en'
import {declension} from '@helpers/functions'
import React, {useState} from 'react'
import styled from 'styled-components'
import {Avatar, Image} from '@ui/Blocks'
import {Link, Icon} from '@ui/Modules'

const RegistryListItemBig = ({data = {}, onLinkClick, className = '', brandColor, ...props}) => {
	return (
		<Link
			href={'/registries/' + data['slug']}
			onClick={onLinkClick}
			style={{borderColor: '#ffffff30'}}
			className="tw-flex tw-h-full tw-max-h-[105px] tw-min-h-[105px] tw-w-full tw-flex-col tw-overflow-hidden tw-rounded-[10px] tw-border"
		>
			<div style={{background: `${brandColor}10`}} className="tw-flex tw-max-h-[31px] tw-min-h-[31px] tw-items-center tw-justify-center tw-text-center">
				<h3 className="tw-text-[12px] tw-leading-[1.1] tw-text-white">Registry</h3>
			</div>

			<div
				style={{borderColor: '#ffffff30', backgroundColor: `${brandColor}60`}}
				className="tw-flex tw-min-h-[73px] tw-items-center tw-gap-[10px] tw-border-t tw-pr-[10px] tw-pl-[8px] lg:tw-pr-[22px] lg:tw-pl-[10.5px]"
			>
				<div className="tw-flex tw-h-full tw-max-h-[48px] tw-w-full tw-max-w-[48px]">
					{data['image_links']?.['h200'] && (
						<Image
							srcset={{
								'2x': data['image_links']?.['h400'] ? data['image_links']['h400'] : '',
								'1x': data['image_links']['h200'],
							}}
							src={data['image_links']['h200']}
							className="tw-flex tw-h-full tw-max-h-full tw-w-auto tw-max-w-none tw-select-none tw-rounded-[12px] tw-drop-shadow-xl"
							alt={data['name']}
						/>
					)}
				</div>
				<div className="tw-flex tw-flex-col tw-gap-[3px] lg:tw-gap-[6px]">
					<div>
						<h3 className="tw-text-[10px] tw-leading-[1.3] tw-text-[#ffffff] lg:tw-text-[12px]">{data['name']}</h3>
					</div>
					<div className="tw-whitespace-nowrap tw-text-[8px] tw-leading-[1.1] tw-text-[#FFFFFF80] lg:tw-text-[11px]">
						Commitment:{' '}
						{data?.['volume'] > 0 && data?.['type'] && (
							<span className="">
								{data['volume']}

								{UnitsLocale[data['type']['slug']]?.['declension_short'] && <>&nbsp;{declension(data['volume'], UnitsLocale[data['type']['slug']]?.['declension_short'])}</>}
							</span>
						)}
					</div>
				</div>
			</div>
		</Link>
	)
}

export default RegistryListItemBig
