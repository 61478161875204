export const Social = [
	{
		title: 'instagram',
		icon: 'app-icon-instagram',
		link: '/',
	},
	{
		title: 'twitter',
		icon: 'app-icon-twitter',
		link: '/',
	},
	{
		title: 'discord',
		icon: 'app-icon-discord',
		link: '/',
	},
]

export const Icons = [
	{
		name: 'World',
		key: '#app-icon-world',
	},
	{
		name: 'Website',
		key: '#app-icon-website',
	},
	{
		name: 'Social Link',
		key: '#app-icon-social-link',
	},
]

export const CropSizes = {
	BannerDesktop: {
		maxHeight: 800,
		minHeight: 800,
		maxWidth: 3840,
		minWidth: 1920,
	},
	BannerTablet: {
		maxHeight: 800,
		minHeight: 800,
		maxWidth: 2048,
		minWidth: 1024,
	},
	BannerMobile: {
		maxHeight: 800,
		minHeight: 800,
		maxWidth: 1536,
		minWidth: 768,
	},
	ListImage: {
		maxHeight: 500,
		minHeight: 500,
		maxWidth: 1000,
		minWidth: 1000,
		aspect: 2 / 1,
	},
	Avatar: {
		minWidth: 160,
		minHeight: 160,
		aspect: 1,
	},
	Certificate: {
		minHeight: 768,
		minWidth: 768,
		aspect: 1,
	},
	CertificateMobile: {
		minHeight: 768,
		minWidth: 768,
		aspect: 4 / 3,
	},
	PageTemplate: {
		minWidth: 300,
	},
}

export const PaymentStatus = {
	created: {
		color: 'tw-text-inherit',
	},
	incomplete: {
		color: 'tw-text-inherit',
	},
	succeeded: {
		color: 'tw-text-inherit',
	},
	refunded: {
		color: 'tw-text-inherit',
	},
	failed: {
		color: 'tw-text-negative',
	},
	completed: {
		color: 'tw-text-primary',
	},
}

export const PromocodeStatus = {
	created: {
		color: 'tw-text-inherit',
	},
	published: {
		color: 'tw-text-primary',
	},
	expired: {
		color: 'tw-text-negative',
	},
	completed: {
		color: 'tw-text-inherit',
	},
}

export const HeaderBannerStyles = {
	align: {
		left: 'tw-justify-start tw-bg-left',
		right: 'tw-justify-end tw-bg-right',
		center: 'tw-justify-center tw-bg-center',
		default: 'tw-justify-center tw-bg-center',
	},
}
