import {forwardRef, Fragment, memo, ReactNode, Dispatch, SetStateAction} from 'react'

import {useAtom, useSetAtom} from 'jotai'
import {ModalState} from '@states/Modal'

import {Dialog, Transition} from '@headlessui/react'

// TODO: Add active class when modal and modalTarget match

interface ModalButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	modalTarget?: string
	children?: React.ReactNode
	className?: string
}

const ModalButton = forwardRef<HTMLButtonElement, ModalButtonProps>(({modalTarget, children, className, ...props}, ref) => {
	const [modal, setModal] = useAtom(ModalState)

	return (
		<button type="button" onClick={() => setModal(modalTarget)} className={`tw-cursor-pointer${className ? ' ' + className : ''}`} ref={ref} {...props}>
			{children}
		</button>
	)
})

//TODO: add transition effect (fade)

interface ModalOverlayProps {
	id?: string
	children?: ReactNode
	isOpen?: boolean
	setOpen?: Dispatch<SetStateAction<any>>
	className?: string
	[key: string]: any
}

const ModalOverlay = memo(({id = '', children, isOpen, setOpen, className = '', ...props}: ModalOverlayProps) => {
	const setModal = useSetAtom(ModalState)

	return (
		<Transition
			show={isOpen}
			as={Fragment}
			enter="tw-transition tw-ease-out tw-duration-200"
			enterFrom="tw-opacity-0"
			enterTo="tw-opacity-100"
			leave="tw-transition tw-ease-in tw-duration-100"
			leaveFrom="tw-opacity-100"
			leaveTo="tw-opacity-0"
		>
			<Dialog open={isOpen} onClose={() => setModal(null)} as="div" className={`ui-overlay${className ? ' ' + className : ''}`}>
				<div className="tw-relative tw-flex tw-min-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center">
					<div onClick={() => setModal(null)} className="tw-absolute tw-inset-0 tw-z-10"></div>
					{children}
				</div>
			</Dialog>
		</Transition>
	)
})

export {ModalButton, ModalOverlay}
