import {destroyCookie, parseCookies} from '@hooks/useCookie'

const AppCallbacks = () => {
	const cookies = parseCookies()

	if (cookies['reload_page']) {
		if (typeof window !== 'undefined') {
			destroyCookie(null, 'reload_page', {
				path: '/',
			})
		
			window.location.reload()
		}
	}
}

export default AppCallbacks
