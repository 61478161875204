import React from 'react'
import RegistryListItem from '@chunks/registries/ListItemBig'

const RegistryItems = ({registries, brandColor, handleLinkClick}) => {
	return (
		<div className="keen-slider__slide card-stack-item tw-h-fit tw-max-h-[560px] tw-min-w-[260px] tw-max-w-[260px] !tw-overflow-visible lg:tw-min-w-[327px] lg:tw-max-w-[327px]">
			<div id={`card-viewer`}>
				<>
					<div
						style={{background: brandColor}}
						className="inter-medium tw-cursor tw-relative tw-top-[40px] tw-flex tw-max-h-[546px] tw-max-h-[430px] tw-min-h-[430px] tw-min-w-[260px] tw-max-w-[260px] tw-select-none tw-overflow-hidden tw-rounded-[21.2px] tw-py-[7px] tw-px-[7px] lg:tw-sticky lg:tw-max-h-[546px] lg:tw-min-h-[546px] lg:tw-min-w-[327px] lg:tw-max-w-[327px]"
					>
						<div
							style={{
								background: 'linear-gradient(162.8deg, rgba(255, 255, 255, 0.15) 1.05%, rgba(255, 255, 255, 0.1) 50.59%, rgba(255, 255, 255, 0.2) 99.15%)',
							}}
							className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full"
						/>
						<div
							style={{border: `2.5px solid ${brandColor}`, background: brandColor}}
							className="tw-relative tw-flex tw-h-auto tw-w-full tw-flex-col tw-overflow-hidden tw-rounded-[15px] tw-text-white"
						>
							<div style={{borderBottom: `2px solid rgba(255, 255, 255, 0.15`}} className="tw-flex tw-w-full tw-justify-center tw-py-[10px] tw-text-center">
								<h3 className="tw-text-[14px] tw-font-[600] tw-text-white lg:tw-text-[18px]">Proof of Authenticity</h3>
							</div>
							<div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-[9px] tw-px-[12px] tw-pt-[11px] lg:tw-px-[18px]">
								{registries.map((registry) => (
									<RegistryListItem key={registry.id} data={registry} brandColor={brandColor} onLinkClick={handleLinkClick} />
								))}
							</div>
						</div>
					</div>
				</>
			</div>
		</div>
	)
}

export default RegistryItems
