import {forwardRef, ReactNode} from 'react'

interface IconProps {
	name?: string
	children?: ReactNode
	ariaHidden?: boolean
	focusable?: boolean
	role?: string
	[key: string]: any
}

const Icon = forwardRef<SVGSVGElement, IconProps>(({name = '', children = null, ariaHidden = true, focusable = false, role = 'img', ...props}, ref) => {
	return (
		<svg ref={ref} aria-hidden={ariaHidden} focusable={focusable} role={role} {...props}>
			{children ? <>{children}</> : name && <use xlinkHref={name} />}
		</svg>
	)
})

export {Icon}
