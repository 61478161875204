import {useEffect, useState} from 'react'
import {getCertificate} from '@actions/_app/certificate'
import {getUserCertificate} from '@actions/_app/user'
import {useRouter} from 'next/router'

export function useCardRelatedRecords(cardSlug) {
	const router = useRouter()

	const [cardData, setCardData] = useState(null)

	useEffect(() => {
		if (cardSlug === null) return

		if (router.query?.user) {
			getUserCertificate(router.query.user, cardSlug).then(({body}) => setCardData(body?.['collectible']))
		} else {
			getCertificate(cardSlug).then(({body}) => setCardData(body))
		}
	}, [cardSlug])

	if (cardData === null) return {isLoading: true}

	const {owner, registries, rewards} = cardData

	const projects = registries?.map(({project}) => project) || []
	const sponsors = rewards?.map(({partner}) => partner) || []

	return {
		isLoading: false,
		card: cardData,
		brand: owner,
		registries: registries || [],
		rewards: rewards || [],
		projects: projects || [],
		sponsors: sponsors || [],
	}
}
