const Image = ({srcset = {}, sizes = {}, src, alt = '', ...props}) => {
	//TODO: make some cleanup and add lazy-load ability
	let src_set = []

	if (Object.keys(srcset).length > 0) {
		Object.entries(srcset).map(([key, value]) => {
			if (value && value.length > 0) {
				src_set.push(value + ' ' + key)
			}
		})

		src_set = src_set.join(', ')
	}

	return <img srcSet={src_set} src={src} alt={alt} {...props} />
}

export {Image}
