import {useCallback, useEffect, useState, Fragment, memo} from 'react'

import {useCarousel} from '@hooks/useCarousel'

import {Icon} from '@ui/Modules'

const Carousel = memo(({className = '', options = {}, arrows = false, buttons = {}, ...props}) => {
	const [ref, api] = useCarousel(options)
	const [selectedIndex, setSelectedIndex] = useState(options['startIndex'] || 0)

	const scrollPrev = useCallback(() => {
		api && api.scrollPrev()
	}, [api])
	const scrollNext = useCallback(() => {
		api && api.scrollNext()
	}, [api])

	const scrollTo = useCallback(
		(index) => {
			api && api.scrollTo(index)
		},
		[api]
	)

	const onSelect = useCallback(() => {
		if (api) {
			setSelectedIndex(api.selectedScrollSnap())
			//TODO:
			//setPrevButtonEnabled(api.canScrollPrev())
			//setNextButtonEnabled(api.canScrollNext())
		}
	}, [api, setSelectedIndex])

	/*
	TODO: происходит переинициализация каждый раз при коллбаке на селект (х3 почему, потом покопаться)
	useEffect(() => {
		if (api) {
			api.reInit()
		}
	}, [api, props])
	*/

	useEffect(() => {
		if (api) {
			api.on('select', onSelect)
		}
	}, [api, onSelect])

	//TODO: переделать всю верстку на ui-carousel

	return (
		<div className={'tw-relative tw-w-full' + (className ? ' ' + className : '')}>
			{arrows && (
				<>
					<button
						data-subtype="carousel-arrow"
						type="button"
						onClick={scrollPrev}
						className="ui-link-secondary tw-absolute tw-top-1/2 tw-left-[10px] tw-z-20 tw--translate-y-1/2 tw-rounded-full tw-bg-[white] tw-p-[2px] tw-text-[#2A2B2E] hover:tw-text-[#2A2B2E]"
					>
						<Icon className="tw-h-[20px] tw-w-[20px] tw-rotate-180" name="#app-icon-arrow-next2" />
					</button>

					<button
						data-subtype="carousel-arrow"
						type="button"
						onClick={scrollNext}
						className="ui-link-secondary tw-absolute tw-top-1/2 tw-right-[10px] tw-z-20 tw--translate-y-1/2 tw-rounded-full tw-bg-[white] tw-p-[2px] tw-text-[#2A2B2E] hover:tw-text-[#2A2B2E]"
					>
						<Icon className="tw-h-[20px] tw-w-[20px]" name="#app-icon-arrow-next2" />
					</button>
				</>
			)}

			{/*TODO: переделать на что-то гибкое + пока временно захардкожены стили*/}
			{Object.keys(buttons).length > 0 && (
				<div className="tw-absolute tw-inset-x-0 tw-bottom-0 tw-z-20 tw-flex tw-items-stretch" data-subtype="carousel-navigation">
					{/*TODO: добавить проверку на существования ключей и лейбов до отрисовки кнопки?*/}
					{buttons.map((button) => (
						<button
							key={button['value']}
							onClick={() => scrollTo(button['value'])}
							className={
								'tw-flex-shrink tw-flex-grow tw-py-[5px] tw-px-[10px] tw-text-[14px] tw-transition-colors tw-duration-200' +
								(selectedIndex == button['value']
									? ' tw-cursor-default tw-bg-primary tw-text-surface-1'
									: ' hover:tw-text--surface-0 tw-bg-surface-1 tw-text-primary hover:tw-bg-primary')
							}
						>
							{button['key']}
						</button>
					))}
				</div>
			)}

			<div className={'tw-z-10 tw-w-full tw-select-none tw-overflow-hidden'} ref={ref} {...props}>
				{props.children}
			</div>
		</div>
	)
})

const Wrapper = ({children, className = '', ...props}) => {
	return (
		<div className={'tw-flex' + (className ? ' ' + className : '')} {...props}>
			{children}
		</div>
	)
}

Carousel.Wrapper = Wrapper

const Item = ({children, className = '', ...props}) => {
	return (
		<div className={'tw-relative tw-flex-shrink-0 tw-flex-grow-0' + (className ? ' ' + className : '')} {...props}>
			{children}
		</div>
	)
}

Carousel.Item = Item

export {Carousel}
