import {fetchQuery, fetchWrapper} from '@helpers/functions'

export async function getUsers(ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users', ctx, options)
}

export async function getUser(id, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/' + id, ctx, options)
}

export async function getUserCertificates(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/' + id + '/tokens' + fetchQuery(queryParams), ctx, options)
}

export async function getUserPayments(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/payments/' + fetchQuery(queryParams), ctx, options)
}

export async function getUserCertificate(id, token_id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/' + id + '/tokens/' + token_id + fetchQuery(queryParams), ctx, options)
}

export async function getUserUnbuyedCertificates(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/' + id + '/unbuyed_collectibles' + fetchQuery(queryParams), ctx, options)
}

export async function getUserRewards(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/' + id + '/rewards' + fetchQuery(queryParams), ctx, options)
}

export async function getUserProjects(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/projects' + fetchQuery({...queryParams, user_id: id}), ctx, options)
}

export async function getUserUnbuyedProjects(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/' + id + '/unbuyed_projects' + fetchQuery({...queryParams, user_id: id}), ctx, options)
}

export async function getUserProgress(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/' + id + '/offset_progress' + fetchQuery(queryParams), ctx, options)
}

export async function getUserFeed(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/feed_subscriptions/posts' + fetchQuery({...queryParams, user_id: id}), ctx, options)
}

export async function getUserFollowings(id, ctx = null, queryParams = {}, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/followings' + fetchQuery({...queryParams, user_id: id}), ctx, options)
}
