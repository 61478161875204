import {forwardRef, memo} from 'react'
import {createPortal} from 'react-dom'

import {useAtom} from 'jotai'
import {SidebarState} from '@states/Sidebar'

//Добавить активный класс при соответствии sidebar и sidebarTarget

const SidebarButton = forwardRef(({sidebarTarget, children, className, ...props}, ref) => {
	const [sidebar, setSidebar] = useAtom(SidebarState)

	return (
		<button type="button" onClick={() => setSidebar(sidebarTarget)} className={'tw-cursor-pointer' + (className ? ' ' + className : '')} ref={ref} {...props}>
			{children}
		</button>
	)
})

const SidebarOverlay = memo(({id, children, isOpen, setOpen, className = '', direction = '', ...props}) => {
	let classList = {}

	//TODO: add top animation

	switch (direction) {
		case 'left':
			classList['default'] = 'tw-transform tw-transition-transform tw-right-full'
			classList['opened'] = 'tw-translate-x-full'

			break

		case 'right':
			classList['default'] = 'tw-transform tw-transition-transform tw-left-full'
			classList['opened'] = 'tw--translate-x-full'

		default:
			classList['default'] = 'tw-opacity-0'
			classList['opened'] = '!tw-opacity-100'
	}

	return (
		<>
			{createPortal(
				<div
					id={id}
					className={
						'tw-fixed tw-inset-y-0 tw-z-20 tw-select-none' +
						(className ? ' ' + className : '') +
						(classList['default'] ? ' ' + classList['default'] : '') +
						(isOpen ? ' ' + classList['opened'] : ' tw-pointer-events-none')
					}
				>
					{children}
				</div>,
				document.getElementById('__next')
			)}
		</>
	)
})

export {SidebarButton, SidebarOverlay}
