/* eslint-disable @next/next/no-html-link-for-pages */
import {useState, useEffect, Fragment} from 'react'
import CertificateListItem from '@chunks/certificates/ListItemBig'
import BrandListItem from '@chunks/brands/ListItemBig'
import ProjectListItem from '@chunks/projects/ListItemBig'
import RegistryListItem from '@chunks/registries/ListItemBig'
import RegistryItems from './RegistryItems'
import RewardsListItem from '@chunks/rewards/ListItem'
import {useCardRelatedRecords} from '@hooks/useCardRelatedRecords'
import {useBrandRelatedCertificates} from '@hooks/useBrandRelatedCertificates'
import {useKeenSlider} from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import {useSetAtom} from 'jotai'
import {ModalState} from '@states/Modal'

import {UserState} from '@states/User'
import {useAtom} from 'jotai'

import {motion} from 'framer-motion'
import {priceWithCurrency, showCurrencyUnits} from '@helpers/functions'
import {useRouter} from 'next/router'

const CircularCarousel = ({children}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [sliderRef, instanceRef] = useKeenSlider({
		loop: true,
		slides: {perView: 'auto', spacing: 50},
		breakpoints: {
			'(max-width: 1280px)': {
				slides: {perView: 'auto', spacing: 60},
			},
		},
		centered: true,
		slideChanged(slider) {
			updateSlidesTilt(slider)
			setActiveIndex(slider.track.details.rel)
		},
		created(slider) {
			updateSlidesTilt(slider)
			setLoaded(true)
		},
		move(slider, e) {
			incrementalUpdate(slider)
		},
	})

	const incrementalUpdate = (slider) => {
		const dragProgress = slider.track.details.dragProgress || 0

		const rotationDegree = dragProgress * 360

		if (sliderRef.current) {
			sliderRef.current.style.transform = `rotate(${rotationDegree}deg)`
		}
	}

	const updateSlidesTilt = (slider) => {
		const totalSlides = slider.slides.length
		const absoluteIndex = slider.track.details.abs
		const centerIndex = absoluteIndex % totalSlides

		slider.slides.forEach((slide, i) => {
			const img = slide.querySelector('#card-viewer')
			if (img) {
				let rotationZ, scale, x, y, opacity

				let relativePosition = i - centerIndex
				if (relativePosition < -totalSlides / 2) {
					relativePosition += totalSlides
				} else if (relativePosition > totalSlides / 2) {
					relativePosition -= totalSlides
				}

				if (relativePosition === 1) {
					// Centered
					rotationZ = 0
					scale = 1.08
					x = 0
					y = -10
					opacity = 1
					blur = 0
				} else if (relativePosition === 0) {
					// Left
					rotationZ = -10
					scale = 0.95
					x = 0
					y = 40
					opacity = 0.8
					blur = '3px'
				} else if (relativePosition === 2) {
					// Right
					rotationZ = 10
					scale = 0.95
					x = 0
					y = 40
					opacity = 0.8
					blur = '3px'
				} else if (relativePosition === -1) {
					rotationZ = -20
					scale = 0.95
					x = 0
					y = 150
					opacity = 0.5
					blur = '3px'
				} else if (relativePosition === -2) {
					rotationZ = -25
					scale = 0.95
					x = 10
					y = 310
					opacity = 0.3
					blur = '3px'
				} else if (relativePosition === 4) {
					rotationZ = 25
					scale = 0.95
					x = -10
					y = 310
					opacity = 0.3
					blur = '3px'
				} else if (relativePosition === -4) {
					rotationZ = -25
					scale = 0.95
					x = -10
					y = 220
					opacity = 0.5
					blur = '3px'
				} else {
					rotationZ = 20
					scale = 0.95
					x = 0
					y = 150
					opacity = 0.5
					blur = '3px'
				}

				img.style.setProperty('--_rotationZ', `${rotationZ}deg`)
				img.style.setProperty('--_scale', scale)
				img.style.setProperty('--_x', `${x}px`)
				img.style.setProperty('--_y', `${y}px`)
				img.style.setProperty('--_opacity', opacity)
				img.style.setProperty('--_blur', blur)
			}
		})
	}

	return (
		<div className="tw-relative">
			<div
				ref={sliderRef}
				className="keen-slider tw-mx-auto tw-ml-[22%] tw-h-full !tw-max-h-[560px] tw-w-full tw-items-center !tw-overflow-visible xl:tw-ml-0 xl:tw-pl-[260px]"
				style={{maxWidth: 1600}}
			>
				{children}
			</div>
			<div className="tw-pointer-events-none tw-absolute tw-left-[38%] tw-top-[0%] tw-z-[9999] tw-mx-auto tw-flex tw-h-full tw-w-full tw-max-w-[390px] tw-items-center tw-justify-between lg:tw-left-[35%] lg:tw-max-w-[480px]">
				<button onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()} className="tw-pointer-events-auto tw-cursor-pointer">
					<img src="/assets/images/icons/arrow_left.svg" alt="" />
				</button>
				<button onClick={(e) => e.stopPropagation() || instanceRef.current?.next()} className="tw-pointer-events-auto tw-cursor-pointer">
					<img src="/assets/images/icons/arrow_right.svg" alt="" />
				</button>
			</div>
		</div>
	)
}

export function CardViewer({slug, onClose: close}) {
	const {isLoading, card, registries, rewards, brand, projects} = useCardRelatedRecords(slug)
	const {addBrands} = useBrandRelatedCertificates()
	const {replace, query, pathname, asPath} = useRouter()
	const brandColor = card?.style_properties?.['background-color'] ?? '#3a3a3a'

	const setModal = useSetAtom(ModalState)

	const router = useRouter()

	const onClose = (...args) => {
		close(...args)
		replace(
			{
				query: {},
			},
			undefined,
			{
				shallow: true,
			}
		).then()
	}

	useEffect(() => {
		if (!isLoading) {
			addBrands([brand])
		}
	}, [addBrands, brand, isLoading])

	/*
	useEffect(() => {
		if (query['card-viewer-id'] === slug) return
		replace(asPath.split('?')[0] + '?card-viewer-id=' + slug + (router.query?.user ? ('&user=' + router.query.user) : ''), undefined, {
			shallow: true,
		}).then()
	}, [asPath, query, replace, slug])
	*/

	if (isLoading) return null

	const handleLinkClick = () => {
		onClose(null)
	}

	const renderSlideItem = (data, Component, index) => (
		<div
			key={data?.id || index}
			className="keen-slider__slide card-stack-item tw-h-fit tw-max-h-[560px] tw-min-w-[260px] tw-max-w-[260px] !tw-overflow-visible lg:tw-min-w-[327px] lg:tw-max-w-[327px]"
		>
			<div id={`card-viewer`} className="tw-rounded-[21px] tw-shadow-xl">
				<Component data={data} brandColor={brandColor} onLinkClick={handleLinkClick} />
			</div>
		</div>
	)

	return (
		<motion.div
			initial={{opacity: 0}}
			animate={{opacity: 1}}
			transition={{delay: 0.2, duration: 0.5}}
			className="tw-relative tw-z-[999999] tw-mx-auto tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden tw-bg-black lg:tw-overflow-y-auto"
		>
			<div className="z-10 tw-absolute tw-h-full tw-w-full tw-scale-[1.2] tw-blur-[50px]">
				<img alt="" src={brand?.banner_links?.desktop_x2} className="tw-flex tw-h-full tw-w-full tw-object-cover tw-opacity-70" />
			</div>
			<div className="tw-absolute tw-top-0 tw-left-0 tw-z-30 tw-flex tw-w-full tw-items-center tw-justify-between tw-px-[20px] tw-py-[16px] lg:tw-px-[40px] lg:tw-py-[24px]">
				<a href="/" className="tw-flex tw-w-fit">
					<img src="/assets/images/logo_ucv.png" className="tw-flex tw-h-full tw-max-h-[25px] tw-w-full tw-max-w-[150px]" alt="" />
				</a>
				<div className="tw-flex tw-w-fit tw-gap-2">
					{/*<a
						href={process.env.NEXT_PUBLIC_APP_URL + '/payment/form?id=' + slug}
						target="_blank"
						rel="noreferrer"
						className="ui-button ui-button-primary tw-z-[99999999] tw-max-h-[44px] tw-min-h-[44px] tw-whitespace-nowrap tw-px-[14px] tw-py-[10px] tw-text-[16px] tw-leading-[1.1] lg:tw-px-[16px] lg:tw-text-[18px]"
					>
						Buy for {priceWithCurrency(card.price, card.currency)} {showCurrencyUnits(card.currency)}
					</a>*/}
					<button
						type="button"
						onClick={() => {
							setModal(null)

							replace(router.asPath.split('?')[0], undefined, {
								shallow: true,
							}).then()
						}}
						className="ui-button ui-button-primary tw-z-[99999999] tw-max-h-[44px] tw-px-[14px] tw-py-[10px] tw-text-[18px] lg:tw-px-[16px]"
					>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
								<path
									d="M21.9076 4.54051C17.0628 -0.304342 9.20662 -0.304342 4.36177 4.54051C-0.483078 9.38536 -0.483079 17.2415 4.36177 22.0864C9.20662 26.9312 17.0628 26.9312 21.9076 22.0864C26.7525 17.2415 26.7525 9.38536 21.9076 4.54051ZM16.7901 18.431L13.1347 14.7756L9.47932 18.431C9.07503 18.8353 8.42072 18.8345 8.01716 18.431C7.61361 18.0274 7.61287 17.3731 8.01716 16.9688L11.6725 13.3134L8.01716 9.65805C7.61361 9.2545 7.61288 8.60018 8.01716 8.1959C8.42145 7.79161 9.07576 7.79234 9.47932 8.1959L13.1347 11.8513L16.7901 8.1959C17.1944 7.79161 17.8487 7.79234 18.2522 8.1959C18.6558 8.59945 18.6565 9.25377 18.2522 9.65805L14.5969 13.3134L18.2522 16.9688C18.6558 17.3724 18.6565 18.0267 18.2522 18.431C17.848 18.8353 17.1936 18.8345 16.7901 18.431Z"
									fill="#029ECE"
								/>
							</svg>
						</div>
						<span className="tw-hidden md:tw-flex">Close</span>
					</button>
				</div>
			</div>
			<motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.4, duration: 0.5}} className="tw-z-20 tw-mt-[30px] tw-flex tw-w-full tw-justify-center lg:tw-mt-[100px]">
				<CircularCarousel>
					{renderSlideItem(brand, BrandListItem, {brandColor})}
					{renderSlideItem(card, CertificateListItem)}
					{rewards.map((rewards, idx) => renderSlideItem(rewards, RewardsListItem, idx, {brandColor}))}
					{projects.map((project, idx) => renderSlideItem(project, ProjectListItem, idx, {brandColor}))}
					<RegistryItems registries={registries} brandColor={brandColor} handleLinkClick={handleLinkClick} />
					{renderSlideItem(brand, BrandListItem, {brandColor})}
					{renderSlideItem(card, CertificateListItem)}
					{rewards.map((rewards, idx) => renderSlideItem(rewards, RewardsListItem, idx, {brandColor}))}
					{projects.map((project, idx) => renderSlideItem(project, ProjectListItem, idx, {brandColor}))}
					<RegistryItems registries={registries} brandColor={brandColor} handleLinkClick={handleLinkClick} />
					{renderSlideItem(brand, BrandListItem, {brandColor})}
					{renderSlideItem(card, CertificateListItem)}
					{rewards.map((rewards, idx) => renderSlideItem(rewards, RewardsListItem, idx, {brandColor}))}
					{projects.map((project, idx) => renderSlideItem(project, ProjectListItem, idx, {brandColor}))}
					<RegistryItems registries={registries} brandColor={brandColor} handleLinkClick={handleLinkClick} />
				</CircularCarousel>
			</motion.div>
			{brand && (
				<motion.div
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					transition={{delay: 0.7, duration: 0.5}}
					className="tw-z-10 tw-flex tw-h-fit tw-w-full tw-justify-center tw-pt-[80px] lg:tw-pt-[60px]"
				>
					<div className="tw-flex tw-w-[270px] lg:tw-w-[350px]">
						{/*<a
							href={'/brands/' + brand['slug']}
							className="ui-button ui-button-primary tw-flex tw-max-h-[44px] tw-w-fit tw-cursor-pointer tw-border tw-border-transparent tw-bg-[#ffffff] tw-px-[18.5px] tw-text-[18px] tw-text-black hover:tw-bg-white hover:tw-text-black"
						>
							View in profile
						</a>*/}
						<div className="tw-flex tw-w-full">
							<a
								href={process.env.NEXT_PUBLIC_APP_URL + '/payment/form?id=' + slug}
								target="_blank"
								rel="noreferrer"
								className="ui-button ui-button-primary tw-flex tw-max-h-[44px] tw-min-w-full tw-cursor-pointer tw-border tw-border-transparent tw-bg-[#029ECE] tw-px-[18.5px] tw-text-[18px] tw-text-white hover:tw-bg-[#029ECE] hover:tw-text-white"
							>
								{card?.price <= 0 ? 'Claim Card' : 'Buy Card'}
							</a>
						</div>
					</div>
				</motion.div>
			)}
		</motion.div>
	)
}
