import {Link} from '@ui/modules/Link'
import React from 'react'
import styled from 'styled-components'

import {useRouter} from 'next/router'
import {useSetAtom} from 'jotai'
import {ModalState} from '@states/Modal'
import {SelectedCardState} from '@states/SelectedCard'

export function MiniCertificateBig({style_properties, slug, image_links, avatar, image, name, ...props}) {
	const router = useRouter()
	const setModal = useSetAtom(ModalState)
	const setSelectedCardSlug = useSetAtom(SelectedCardState)

	const brandColor = style_properties?.['background-color'] ?? '#3a3a3a'
	const certImageStyle = {
		width: '100%',
		height: 'auto',
		background: `linear-gradient(0deg, ${brandColor} 0%, ${brandColor}80 100%)`,
		backgroundImage: `url("${image}")`,
		backgroundSize: 'cover',
	}

	const GradientOverlay = styled.div`
		&::before {
			content: '';
			position: absolute;
			display: flex;
			top: 0px;
			left: -1px;
			width: 104%;
			height: 104%;
			mask-position: center;
			mask-repeat: no-repeat;
			background: linear-gradient(0deg, ${brandColor} 10%, ${brandColor}00 40%);
			mask-mode: alpha;
		}
	`

	const viewCard = () => {
		if (slug) {
			setSelectedCardSlug(slug)
			setModal('card-viewer')

			router.push(router.asPath.split('?')[0] + '?card-viewer-id=' + slug, undefined, {
				shallow: true,
			})
		}
	}

	return (
		<button type="button" onClick={() => viewCard()} {...props}>
			<div
				style={{background: brandColor}}
				className="tw-relative tw-flex tw-max-h-[80px] tw-min-h-[80px] tw-w-full tw-min-w-[60px] tw-max-w-[60px] tw-select-none tw-overflow-hidden tw-rounded-[4px] tw-py-[1px] tw-px-[1px] lg:tw-sticky"
			>
				<div
					style={{
						background: 'linear-gradient(162.8deg, rgba(255, 255, 255, 0.15) 1.05%, rgba(255, 255, 255, 0.1) 50.59%, rgba(255, 255, 255, 0.2) 99.15%)',
					}}
					className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full"
				/>
				<div style={{border: `2.5px solid ${brandColor}`, background: brandColor}} className="tw-relative tw-flex tw-h-auto tw-w-full tw-overflow-hidden tw-rounded-[4px] tw-border-[1px]">
					<div style={{background: brandColor}} className="tw-z-10 tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-overflow-hidden">
						<div className="">
							<div className="tw-relative tw-flex tw-min-h-[55px] tw-w-full tw-overflow-hidden">
								<>
									<GradientOverlay style={certImageStyle} className="tw-h-full tw-w-full tw-object-cover" />
								</>
								<div className="tw-absolute tw-flex tw-h-full tw-w-full tw-justify-center tw-pt-[2px]">
									<div className="tw-absolute tw-flex tw-h-full tw-w-full tw-pl-[4px]">
										<div className="tw-h-[21.5px] tw-w-[21.5px] tw-rounded-full ">
											<img alt="" src={avatar} className="tw-flex tw-rounded-full" />
										</div>
									</div>
									<div className="tw-h-ful tw-absolute tw-top-[5.5px] tw-left-[-8.5px] tw-flex tw-w-full tw-items-center tw-justify-center tw-overflow-hidden">
										{/** 
										<div className="">
											<svg xmlns="http://www.w3.org/2000/svg" width="300" height="326" viewBox="0 0 300 326" fill="none">
												<path
													opacity="0.5"
													fillRule="evenodd"
													clipRule="evenodd"
													d="M176.11 49.8754C162.654 62.9454 157.109 81.0212 159.444 98.2289C159.477 98.467 159.576 99.1544 159.653 99.6929C159.692 99.9585 159.725 100.188 159.742 100.31C159.901 101.43 159.911 101.54 159.998 102.657C161.385 119.739 155.801 136.391 143.059 149.134C130.307 161.886 113.353 167.177 96.6852 166.043C95.3915 165.956 94.1105 165.776 92.8781 165.603L92.7188 165.578C92.4554 165.541 92.3211 165.524 92.0605 165.489C74.8843 163.184 56.86 168.74 43.8105 182.175C22.1921 204.423 22.7094 240.656 44.9382 262.296C67.1187 283.887 102.604 283.704 124.56 261.747C137.835 248.472 142.842 230.521 140.482 212.958C138.046 194.829 143.478 176.727 157.065 163.14C170.664 149.541 189.19 144.531 206.864 146.555C224.269 148.552 242.396 143.89 255.661 130.625C277.618 108.669 277.801 73.1831 256.21 51.0032C234.57 28.7744 198.358 28.2571 176.11 49.8754ZM92.1957 164.498C92.4622 164.535 92.5328 164.548 92.8 164.586L92.9993 164.61C94.2432 164.785 95.4951 164.96 96.7531 165.045C113.165 166.162 129.826 160.953 142.351 148.427C154.877 135.901 160.366 119.54 159.002 102.735C158.916 101.637 158.908 101.549 158.752 100.451C158.735 100.333 158.703 100.108 158.665 99.8458C158.587 99.3049 158.486 98.6054 158.453 98.3634C156.077 80.8558 161.72 62.4594 175.414 49.1581C198.057 27.1548 234.902 27.6817 256.927 50.3056C278.899 72.877 278.713 108.988 256.369 131.332C242.85 144.851 224.402 149.574 206.75 147.548C189.335 145.554 171.125 150.494 157.772 163.847C144.419 177.2 139.076 194.987 141.473 212.825C143.871 230.663 138.786 248.936 125.267 262.454C102.923 284.798 66.8126 284.985 44.2406 263.012C21.6168 240.988 21.0898 204.123 43.0931 181.479C56.3738 167.805 74.7189 162.152 92.1957 164.498Z"
													fill="white"
												/>
											</svg>
										</div>
										*/}
									</div>
								</div>
							</div>
							<div className="tw-pl-[2px] tw-text-white">
								<div className="tw-flex tw-w-full">
									<div className="">
										<h2 className="tw-line-clamp-2 tw-text-left tw-text-[7px] tw-font-[500] tw-leading-[1.2]">{name}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</button>
	)
}
