import {Image} from '@ui/Blocks'

const Avatar = ({className = '', srcset = {}, src, alt = "", ...props}) => {
	return (
        <div className={"ui-avatar" + (className ? (" " + className) : "")} {...props}>
            <Image
                srcset={srcset}
                src={src}
                alt={alt}
            />
        </div>
    )
}

export {Avatar}
