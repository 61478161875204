import {useRouter} from 'next/router'

const Link = ({href = '', children = null, prefetch = true, ...props}) => {
	const router = useRouter()

    function handleClick(e) {
        e.preventDefault()
        e.stopPropagation()

        if (router) {
            if (prefetch) {
                //router.prefetch(href)
            }

            router.push(href)
        }
    }

	return (
		<a href={href} onClick={handleClick} {...props}>{children}</a>
	)
}

export {Link}