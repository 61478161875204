import {Icon} from '@ui/Modules'

const Message = ({icon = '', title = '', children = null, className = '', ...props}) => {
	return (
        <div className={"ui-message" + (className ? (" " + className) : "")} {...props}>
            {icon && (
                <Icon name={icon} />
            )}

            <div className="ui-message-body">
                {title && (
                    <h3 className="ui-message-title">{title}</h3>
                )}
                <div className="ui-message-content">
                    {children}
                </div>
            </div>
        </div>
    )
}

export {Message}
