import {memo, Fragment, useEffect, useState} from 'react'

import {Link, Icon, Menu} from '@ui/Modules'

const Breadcrumbs = memo(({items = {}, showed = 3, homeIcon = '#app-icon-dots', className = ''}) => {
	const [itemsShowed, setItemsShowed] = useState(items)
	const [itemsHidden, setItemsHidden] = useState({})

	useEffect(() => {
		const total = Object.keys(items).length

		let showedCrumbs = {}
		let hiddenCrumbs = {}

		Object.keys(items).forEach(function (key, index) {
			if (index < total - showed) {
				hiddenCrumbs[key] = items[key]
			} else {
				showedCrumbs[key] = items[key]
			}
		})

		setItemsShowed(showedCrumbs)
		setItemsHidden(hiddenCrumbs)
	}, [items])

	return (
		<>
			{Object.keys(itemsShowed).length > 0 > 0 && (
				<div className={'ui-breadcrumbs' + (className ? ' ' + className : '')}>
					{Object.keys(itemsHidden).length > 0 && (
						<Menu items={itemsHidden} menuClassName="ui-arrow">
							<Icon name={homeIcon} />
						</Menu>
					)}

					{Object.entries(itemsShowed).map(([title, slug], index) => (
						<Fragment key={slug}>
							{!(Object.keys(itemsHidden).length == 0 && index == 0) && <Icon name="#app-icon-arrow-next" />}

							{slug.length > 0 && (
								<Link href={slug}>
									{title}
								</Link>
							)}

							{slug.length == 0 && <span>{title}</span>}
						</Fragment>
					))}
				</div>
			)}
		</>
	)
})

export {Breadcrumbs}
