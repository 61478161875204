import React, {useState} from 'react'
import {truncateString} from '@helpers/functions'
import {HeaderBannerStyles} from '@helpers/constants'
import styled from 'styled-components'
import {Avatar, Image} from '@ui/Blocks'
import {Link, Icon} from '@ui/Modules'
import {useBrandRelatedCertificates} from '@hooks/useBrandRelatedCertificates'
import {MiniCertificateBig} from '@chunks/brands/MiniCertificateBig'

const BrandListItemBig = ({data = {}, onLinkClick, brandColor, className = '', ...props}) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const {certificates, brands, addBrands} = useBrandRelatedCertificates()
	const miniCertificates = certificates[data['slug']] ?? null

	const toggleExpand = () => {
		setIsExpanded(!isExpanded)
	}

	const truncateString = (str = '', maxLength, suffix, preserveWords) => {
		if (str.length <= maxLength) return str
		const truncatedString = preserveWords ? str.substr(0, str.lastIndexOf(' ', maxLength)) : str.substr(0, maxLength)
		return truncatedString + suffix
	}

	return (
		<div className={'' + (className ? ' ' + className : '')} {...props}>
			<div
				style={{background: brandColor}}
				className="inter-medium tw-cursor tw-relative tw-top-[40px] tw-flex tw-max-h-[430px] tw-min-h-[430px] tw-min-w-[260px] tw-max-w-[260px] tw-select-none tw-overflow-hidden tw-rounded-[21.2px] tw-py-[7px] tw-px-[7px] lg:tw-sticky lg:tw-max-h-[546px] lg:tw-min-h-[546px] lg:tw-min-w-[327px] lg:tw-max-w-[327px]"
			>
				<div
					style={{
						background: 'linear-gradient(162.8deg, rgba(255, 255, 255, 0.15) 1.05%, rgba(255, 255, 255, 0.1) 50.59%, rgba(255, 255, 255, 0.2) 99.15%)',
					}}
					className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full "
				/>
				<div
					style={{
						border: `2.5px solid ${brandColor}`,
						background: brandColor,
					}}
					className="tw-relative tw-flex tw-h-auto tw-w-full tw-overflow-hidden tw-rounded-[15px]"
				>
					<div style={{background: brandColor}} className="tw-z-10 tw-flex tw-h-full tw-w-full tw-flex-col tw-overflow-hidden">
						<div className="tw-relative tw-flex tw-flex-col tw-items-start tw-justify-between tw-gap-y-[20px] tw-px-[20px] tw-pt-[20px]">
							<div
								className={
									'tw-absolute tw-inset-0 tw-bottom-[40px] tw-z-10 tw-flex tw-max-h-[56px] tw-min-h-[56px] tw-items-center tw-overflow-hidden tw-bg-surface-2' +
									(data['banner_images_properties']?.['align']
										? ' ' + HeaderBannerStyles['align'][data['banner_images_properties']['align']]
										: ' ' + HeaderBannerStyles['align']['default'])
								}
								style={{backgroundImage: data['banner_links']?.['desktop_x2'] ? 'none' : 'url(/assets/images/pages/brands/headers/1.jpg)'}}
							>
								{data['banner_links']?.['desktop_x2'] && (
									<Image
										srcset={{
											'2x': data['list_image_links']?.['h500']
												? data['list_image_links']['h500']
												: data['banner_links']?.['desktop_x2']
												? data['banner_links']['desktop_x2']
												: '',
											'1x': data['list_image_links']?.['h250']
												? data['list_image_links']['h250']
												: data['banner_links']?.['desktop_x1']
												? data['banner_links']['desktop_x1']
												: '',
										}}
										src={data['banner_links']['desktop_x2']}
										className="tw-h-full tw-min-w-full tw-flex-shrink-0 tw-flex-grow-0 tw-select-none tw-object-cover"
										alt={data['name']}
									/>
								)}
							</div>
							<Avatar
								srcset={{
									'2x': data['avatar_links']?.['160x160'] ? data['avatar_links']['160x160'] : '/assets/images/pages/users/avatars/160x160.jpg',
									'1x': data['avatar_links']?.['96x96'] ? data['avatar_links']['96x96'] : '/assets/images/pages/users/avatars/96x96.jpg',
								}}
								src={data['avatar_links']?.['96x96'] ? data['avatar_links']['96x96'] : '/assets/images/pages/users/avatars/96x96.jpg'}
								className="ui-avatar-primary tw-relative tw-z-20 tw-h-[67px] tw-max-h-[67px] tw-w-[67px] tw-max-w-[67px]"
								alt={data['name']}
							/>
						</div>
						<div className="tw-mt-[12px] tw-flex tw-flex-col tw-px-[10px] lg:tw-mt-[20px] lg:tw-px-[19px]">
							<div className="tw-font-bold">
								<h3 className="tw-mb-[8px] tw-mr-[10px] tw-text-[16px] tw-font-[600] tw-leading-[1.2] tw-text-white lg:tw-text-[18px]">{data['name']}</h3>
							</div>

							{miniCertificates == 0 ? (
								<div>
									<div className="tw-text-[10px] tw-leading-[1.3] tw-text-white lg:tw-text-[12.5px]">
										<span dangerouslySetInnerHTML={{__html: data['description']}} />
									</div>
								</div>
							) : (
								<div>
									<div className={`${isExpanded ? '' : 'tw-max-h-[60px]'} tw-text-[10px] tw-leading-[1.3] tw-text-white`}>
										<span dangerouslySetInnerHTML={{__html: isExpanded ? data['description'] : truncateString(data['description'], 145, '...', true)}} />
										{!isExpanded && (
											<button className="tw-ml-[5px] tw-text-[#FFFFFF80] tw-underline" onClick={toggleExpand}>
												Read more
											</button>
										)}
									</div>
								</div>
							)}
						</div>
						<div className="tw-mt-[12px] tw-flex tw-max-h-[157px] tw-flex-col tw-gap-y-[6px] tw-overflow-hidden tw-px-[10px] lg:tw-mt-[20px] lg:tw-max-h-[260px] lg:tw-px-[19px]">
							<div className="tw-flex tw-w-full tw-flex-row tw-justify-between">
								{miniCertificates > 1 ? (
									<div className="tw-font-bold">
										<span className="tw-mr-[10px] tw-text-[12px] tw-text-white lg:tw-text-[14px]">Cards</span>
									</div>
								) : (
									''
								)}

								<div />
							</div>
							<div className="tw-grid tw-grid-cols-4 tw-gap-[6px] lg:tw-grid-cols-4">
								{Array.isArray(miniCertificates) ? (
									miniCertificates.map((cert) => <MiniCertificateBig onClick={onLinkClick} key={cert.slug} {...cert} brandColor={brandColor} />)
								) : (
									<></>
								)}
							</div>
						</div>
						<div className="tw-absolute tw-bottom-0 tw-z-10 tw-mt-auto tw-flex tw-h-fit tw-w-full tw-flex-row tw-justify-between tw-bg-[#0000002a] tw-px-[20px] tw-pt-[16px] tw-pb-[12.5px]">
							<div></div>
							<div className="tw-flex tw-flex-row tw-items-center tw-gap-[15px]">
								<div className="tw-flex tw-h-full tw-w-fit tw-items-center">
									<Link
										href={'/brands/' + data['slug']}
										onClick={onLinkClick}
										className="w-fit inter-bold tw-min-h-[26px] tw-min-w-[122px] tw-items-center tw-rounded-full tw-bg-white tw-px-[4px] tw-py-[6px] tw-text-center tw-text-[11.5px] tw-leading-[1.2] tw-text-[#2D2D2D] hover:tw-text-[#2D2D2D]"
									>
										View Brand
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BrandListItemBig
