import {useState, useEffect, Fragment} from 'react'
import CertificateListItem from '@chunks/certificates/ListItemBig'
import {useCardRelatedRecords} from '@hooks/useCardRelatedRecords'
import {useBrandRelatedCertificates} from '@hooks/useBrandRelatedCertificates'
import 'keen-slider/keen-slider.min.css'
import {motion} from 'framer-motion'

export function CardViewerSocialProduct({slug, onClose}) {
	const {isLoading, card, registries, rewards, sponsors, brand, projects} = useCardRelatedRecords(slug)
	const {addBrands} = useBrandRelatedCertificates()

	useEffect(() => {
		if (isLoading) return

		addBrands([brand])
	}, [addBrands, brand, isLoading])

	if (isLoading) return <></>

	const handleLinkClick = (event) => {
		onClose(null)
	}

	return (
		<motion.div
			initial={{opacity: 0}}
			animate={{opacity: 1}}
			transition={{delay: 0.01, duration: 0.5}}
			className=""
		>
			<CertificateListItem data={card} />
		</motion.div>
	)
}
