import {memo, useEffect, useState} from 'react'

import {Icon} from '@ui/Modules'

const Table = memo(({children, headers = [], sort = null, setSort = null, className = '', ...props}) => {
	const [sortBy, setSortBy] = useState(null)
	const [sortDir, setSortDir] = useState(null)

	useEffect(() => {
		if (sort) {
			setSortBy(sort ? sort.split(':')[0] : null)
			setSortDir(sort ? sort.split(':')[1] : null)
		}
	}, [sort])

	const handleSort = (head) => {
		if (setSort && head['sortBy'] && Object.keys(head['sortDir']).length) {
			if (head['sortBy'] === sortBy) {
				let sortIndex = head['sortDir'].indexOf(sortDir) ?? head['sortDir'][0]

				if (head['sortDir'][sortIndex] === sortDir && sortIndex !== head['sortDir'].length - 1) {
					sortIndex += 1
				} else {
					sortIndex = 0
				}

				setSort(head['sortBy'] + ':' + head['sortDir'][sortIndex])
			} else {
				setSort(head['sortBy'] + ':' + head['sortDir'][0])
			}
		}
	}

	return (
		<table className={'ui-table' + (className && (' ' + className))}>
			{Object.keys(headers).length > 0 && (
				<thead>
					<tr>
						{headers.map((item, index) => (
							<th colSpan={item['colSpan']} className={item['className']} key={item['title'] + ' ' + index} rowSpan={item['rowSpan']}>
								{item['title'] && (
									<>
										{item['sortBy'] && item['sortDir'] && (
											<a onClick={() => handleSort(item)} className={item['sortBy'] ? 'tw-cursor-pointer' : ''}>
												{item['title']}

												{sortBy && sortDir && sortBy === item['sortBy'] && (
													<Icon name="#icon-chevron-down" className={sortDir === 'asc' ? ' tw-rotate-180' : ''} />
												)}
											</a>
										)}

										{!(item['sortBy'] && item['sortDir']) && <>{item['title']}</>}
									</>
								)}
							</th>
						))}
					</tr>
				</thead>
			)}
			<tbody>{children}</tbody>
		</table>
	)
})

export {Table}
