import {autoUpdate, useClick, useFloating, useHover, useInteractions, useClientPoint, offset as floatingOffset, shift as floatingShift, flip as floatingFlip, useMergeRefs} from '@floating-ui/react'
import {cloneElement, createElement, memo, useEffect, useState, isValidElement} from 'react'

const Tooltip = memo(({action = 'hover', children, followCursor = false, className = null, close = null, content, position = 'top', as = 'div', offset = {}, ...props}) => {
	const [isOpen, setIsOpen] = useState(false)

	const {context, refs, floating, reference, strategy, x, y} = useFloating({
		strategy: 'fixed',
		placement: position,
		onOpenChange: setIsOpen,
		open: isOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			floatingOffset(Object.keys(offset).length > 0 ? offset : {mainAxis: 16, crossAxis: 8}),
			floatingShift({
				padding: 16,
			}),
			floatingFlip(),
		],
	})

	const {getReferenceProps, getFloatingProps} = useInteractions([
		useHover(context, {enabled: action === 'hover'}),
		useClick(context, {enabled: action === 'click'}),
		useClientPoint(context, {
			enabled: followCursor,
		}),
	])

	useEffect(() => {
		if (!isOpen && close) {
			close()
		}
	}, [close, isOpen])

	const ref = useMergeRefs([refs.setReference, children.ref ? children.ref : null])

	return (
		<>
			{isValidElement(children) && children?.type && cloneElement(children, getReferenceProps({ref, ...children.props}))}

			{!isValidElement(children) && createElement(as, getReferenceProps({ref, ...props}), children)}

			{isOpen && (
				<div
					{...getFloatingProps({
						ref: refs.setFloating,
						className: 'ui-tooltip' + (followCursor ? ' ui-tooltip-following' : '') + (className && ' ' + className),
						style: {
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
						},
					})}
					dangerouslySetInnerHTML={{__html: content}}
				/>
			)}
		</>
	)
})

export {Tooltip}
