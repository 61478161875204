import Head from 'next/head'
import {useEffect} from 'react'

import {Provider, useAtom} from 'jotai'
import {AppState} from '@states/App'

import {parseCookies} from '@hooks/useCookie'
import {NotificationContainer} from '@ui/Modules'

import AppUseAmplitude from '@chunks/_app/_amplitude'
import AppCallbacks from '@chunks/_app/_callbacks'
import AppHydration from '@chunks/_app/_hydration'
import AppModals from '@chunks/_app/Modals'
import AppIcons from '@chunks/_app/Icons'

import '@styles/app.css'
import {BrandRelatedCertificatesProvider} from '@context/BrandRelatedCertificates'

//TODO: кажется, оно все равно инициализируется миллион раз - переделать, когда некст станет умнее
App.getInitialProps = (context) => {
	const cookies = parseCookies(context?.['ctx'])
	const userAgent = context?.['ctx']?.['req']?.['headers']?.['user-agent']

	return {
		cookies,
		userAgent,
	}
}

function App({Component, pageProps, cookies, userAgent}) {
	const [app, setApp] = useAtom(AppState)

	useEffect(() => {
		setApp((app) => ({
			...app,
			init: true,
		}))
	}, [app.init, setApp])

	return (
		<>
			<Head>
				<meta charSet="UTF-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="DC.format" content="text/html" />
				<meta name="DC.coverage" content="World" />

				<meta property="og:site_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
				<meta name="DC.Creator" content={process.env.NEXT_PUBLIC_APP_NAME} />
				<meta property="og:email" content={process.env.NEXT_PUBLIC_EMAIL} />

				<link rel="shortcut icon" href="/favicon.ico" />
				<link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
				<link rel="manifest" href="/assets/favicons/site.webmanifest" />
				<link rel="mask-icon" href="/assets/favicons/safari-pinned-tab.svg" color="#3d24fb" />
				<meta name="theme-color" content="#ffffff" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="msapplication-config" content="/assets/favicons/browserconfig.xml" />

				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<meta name="google" content="notranslate" />
				<meta name="format-detection" content="telephone=no" />
				<meta httpEquiv="x-rim-auto-match" content="none" />

				<meta name="DC.Language" content="en_US" />
				<meta property="og:locale" content="en_US" />

				{/* <link rel="preload" as="font" href="/assets/fonts/DMSans/DMSans-Regular.woff2" type="font/woff2" crossOrigin="anonymous" />
				<link rel="preload" as="font" href="/assets/fonts/DMSans/DMSans-Bold.woff2" type="font/woff2" crossOrigin="anonymous" /> */}
				<link rel="preload" as="font" href="/assets/fonts/DMMono/DMMono-Regular.woff2" type="font/woff2" crossOrigin="anonymous" />

				<meta name="facebook-domain-verification" content="ar227285uwvrmwsa2ht8gm7x1qmdpo" />
			</Head>

			<Provider>
				<BrandRelatedCertificatesProvider>
					{!app?.['init'] && <AppHydration userAgent={userAgent} cookies={cookies} />}

					<AppCallbacks />

					<AppUseAmplitude />

					<AppIcons />
					<Component {...pageProps} />

					<AppModals />

					<NotificationContainer containerClassName="tw-fixed tw-w-[320px] tw-max-w-full tw-inset-y-[8px] tw-right-0 tw-pointer-events-none tw-select-none tw-z-50" />
				</BrandRelatedCertificatesProvider>
			</Provider>
		</>
	)
}

export default App
