import {declension} from '@helpers/functions'
import styled from 'styled-components'
import {UnitsLocale} from '@locales/en'
import {Link, Icon} from '@ui/Modules'
import {Avatar, Image} from '@ui/Blocks'
import {useSetAtom} from 'jotai'
import {ModalState} from '@states/Modal'
import {SelectedCardState} from '@states/SelectedCard'

const CertificateListItemBig = ({data = {}, onLinkClick, className = '', ...props}) => {
	const setModal = useSetAtom(ModalState)
	const setSelectedCardSlug = useSetAtom(SelectedCardState)
	const brandColor = data?.['style_properties']?.['background-color'] || '#3a3a3a'
	const certImageStyle = {
		width: '100%',
		height: 'auto',
		background: `linear-gradient(0deg, ${brandColor} 0%, ${brandColor}80 100%)`,
		backgroundImage: data?.['image_links']?.['desktop_x2'] ? `url("${data['image_links']['desktop_x2']}")` : 'none',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	}

	const GradientOverlay = styled.div`
		&::before {
			content: '';
			position: absolute;
			top: 0px;
			left: -3px;
			width: 105%;
			height: 102%;
			mask-position: center -20px;
			mask-repeat: no-repeat;
			mask-size: 99.5%;
			mask-image: url(/assets/images/cert-image.svg);
			background: linear-gradient(0deg, ${brandColor} 10%, ${brandColor}00 40%);
			mask-mode: alpha;
		}
	`

	const viewCard = () => {
		if (data['slug']) {
			setSelectedCardSlug(data['slug'])
			setModal('card-viewer')
		}
	}

	let volumeSlice = data?.['volume'] ? data['volume'].slice(0, 2) : null
	if (volumeSlice && data['name'] === 'FY23 Proof of Impact') {
		volumeSlice = data['volume'].slice(-2).reverse()
	}

	return (
		<div>
			<div
				style={{
					background: brandColor,
				}}
				className="inter-medium tw-cursor tw-relative tw-top-[40px] tw-flex tw-max-h-[430px] tw-min-h-[430px] tw-min-w-[260px] tw-max-w-[260px] tw-select-none tw-overflow-hidden tw-rounded-[21.2px] tw-py-[7px] tw-px-[7px] lg:tw-sticky lg:tw-max-h-[546px] lg:tw-min-h-[546px] lg:tw-min-w-[327px] lg:tw-max-w-[327px]"
			>
				<div
					style={{
						background: 'linear-gradient(162.8deg, rgba(255, 255, 255, 0.15) 1.05%, rgba(255, 255, 255, 0.1) 50.59%, rgba(255, 255, 255, 0.2) 99.15%)',
					}}
					className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full "
				/>
				<div style={{border: `2.5px solid ${brandColor}`, background: brandColor}} className="tw-relative tw-flex tw-h-auto tw-w-full tw-overflow-hidden tw-rounded-[15px] tw-border-[2.5px]">
					<div style={{background: brandColor}} className="tw-z-10 tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-overflow-hidden">
						<div className="">
							<div className="tw-relative tw-flex tw-max-h-[220px] tw-min-h-[220px] tw-overflow-hidden lg:tw-max-h-[280px] lg:tw-min-h-[280px]">
								<GradientOverlay style={certImageStyle} className="tw-h-full tw-w-full tw-object-cover" />
								<div className="tw-absolute tw-mt-[10px] tw-flex tw-h-full tw-w-full tw-justify-center tw-pt-[0px] lg:tw-pt-[8px]">
									<div className="tw-absolute tw-flex tw-h-full tw-w-full tw-pl-[20px] lg:tw-pl-[26px]">
										<div className="tw-h-[100px] tw-w-[100px] tw-rounded-full lg:tw-h-[120.5px] lg:tw-w-[120.5px]">
											{data['owner'] && (
												<div className="tw-flex tw-items-center tw-justify-between tw-gap-x-[20px]">
													<Avatar
														srcset={{
															'2x': data['owner']['avatar_links']?.['160x160']
																? data['owner']['avatar_links']['160x160']
																: '/assets/images/pages/users/avatars/96x96.jpg',
															'1x': data['owner']['avatar_links']?.['160x160']
																? data['owner']['avatar_links']['160x160']
																: '/assets/images/pages/users/avatars/96x96.jpg',
														}}
														src={data['owner']['avatar_links']?.['160x160'] ? data['owner']['avatar_links']['160x160'] : '/assets/images/pages/users/avatars/48x48.jpg'}
														className="ui-avatar-primary tw-flex tw-h-[100px] tw-w-[100px] tw-flex-shrink-0 tw-flex-grow-0 tw-border tw-object-cover lg:tw-h-[120.5px] lg:tw-w-[120.5px]"
														alt={data['owner']['name']}
													/>
												</div>
											)}
										</div>
									</div>
									<div className="tw-absolute tw-top-[-34px] tw-left-[-48px] tw-flex tw-h-full tw-max-h-[280px] tw-min-h-[280px] tw-w-full tw-min-w-[346px] tw-max-w-[346px] tw-scale-[0.84] tw-items-center tw-justify-center tw-overflow-hidden lg:tw-top-[4.5px] lg:tw-left-[-13px] lg:tw-scale-[1.07]">
										<div className="">
											<img src="/assets/images/icons/card_outline.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
							<div className="tw-z-20 tw-justify-center tw-px-[12px] tw-pb-[11px] tw-text-white lg:tw-pt-[10px]">
								<div className="tw-mx-auto tw-w-full">
									<div className="tw-mb-[10px]">
										<h2 className="tw-line-clamp-2 tw-relative tw-text-left tw-text-[16px] tw-font-[700] tw-leading-[1.3] lg:tw-text-[20px]">{data['name']}</h2>
									</div>
									<div className="tw-border-y tw-border-t-[#ffffff40] tw-border-b-[#ffffff40] tw-pt-[8px] tw-pb-[8px] tw-text-[9.3px] lg:tw-pt-[14px] lg:tw-pb-[11px]">
										<div className="tw-grid tw-grid-cols-3 tw-items-center tw-gap-y-[4px] tw-gap-x-[16px]">
											{volumeSlice &&
												volumeSlice.map((volume, index) => (
													<div key={index} className="tw-flex tw-flex-col tw-items-center tw-gap-x-[4px] tw-text-center tw-text-[14px]">
														<div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px]">
															<div style={{color: data?.style_properties?.['icon-color'] || 'white'}}>
																{UnitsLocale[volume['type']['slug']]?.['icon'] && (
																	<Icon className="tw-h-[24px] tw-w-[24px] lg:tw-h-[28px] lg:tw-w-[28px]" name={'#' + UnitsLocale[volume['type']['slug']]['icon']} />
																)}
															</div>
															<div className="tw-text-[14px] tw-leading-[1.1] lg:tw-text-[17px]">{volume['volume']?.toLocaleString('en-US')}</div>
														</div>
														<div className="tw-line-clamp-2 tw-mt-[7px] tw-text-center tw-text-[9.3px] tw-leading-[1.1] lg:tw-mt-[5px] lg:tw-text-[11px]">
															{UnitsLocale[volume['type']['slug']]?.['declension_short'] && (
																<>{declension(volume['volume'], UnitsLocale[volume['type']['slug']]?.['declension_short'])}</>
															)}
														</div>
													</div>
												))}
											<div className="max-w-[24px] tw-flex tw-flex-col tw-items-center tw-gap-x-[4px] tw-text-center tw-text-[14px]">
												<div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px]">
													<div>
														{data['owner'] && (
															<div className="tw-flex tw-max-h-[24px] tw-max-w-[24px] tw-items-center tw-justify-between tw-gap-x-[20px] tw-object-contain lg:tw-max-h-[28px] lg:tw-max-w-[28px]">
																<Image
																	srcset={{
																		'2x': data['owner']['avatar_links']?.['96x96']
																			? data['owner']['avatar_links']['96x96']
																			: '/assets/images/pages/users/avatars/96x96.jpg',
																		'1x': data['owner']['avatar_links']?.['96x96']
																			? data['owner']['avatar_links']['96x96']
																			: '/assets/images/pages/users/avatars/96x96.jpg',
																	}}
																	src={
																		data['owner']['avatar_links']?.['96x96']
																			? data['owner']['avatar_links']['96x96']
																			: '/assets/images/pages/users/avatars/48x48.jpg'
																	}
																	className="ui-avatar-primary tw-h-[24px] tw-w-[24px] tw-flex-shrink-0 tw-flex-grow-0 tw-border"
																	alt={data?.['owner']?.['name'] || ''}
																/>
															</div>
														)}
													</div>
												</div>
												{data?.['owner']?.['name'] && (
													<div className="tw-line-clamp-2 tw-mt-[7px] tw-text-center tw-text-[9.3px] tw-leading-[1.1] lg:tw-mt-[5px] lg:tw-text-[11px]">
														{data['owner']['name']}
													</div>
												)}
											</div>
										</div>
									</div>
									{data['owner'] && (
										<div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-pt-[15px] tw-text-center lg:tw-pt-[16px]">
											{data['owner'] && (
												<div className="tw-mr-[8px] tw-flex tw-max-h-[20px] tw-max-w-[20px] tw-items-center tw-justify-between tw-gap-x-[20px] tw-object-contain lg:tw-max-h-[22px] lg:tw-max-w-[22px]">
													<Avatar
														srcset={{
															'2x': data['owner']['avatar_links']?.['96x96'] ? data['owner']['avatar_links']['96x96'] : '/assets/images/pages/users/avatars/96x96.jpg',
															'1x': data['owner']['avatar_links']?.['96x96'] ? data['owner']['avatar_links']['96x96'] : '/assets/images/pages/users/avatars/96x96.jpg',
														}}
														src={data['owner']['avatar_links']?.['96x96'] ? data['owner']['avatar_links']['96x96'] : '/assets/images/pages/users/avatars/96x96.jpg'}
														className="ui-avatar-primary tw-h-[20px] tw-w-[20px] tw-flex-shrink-0 tw-flex-grow-0 tw-border"
														alt={data['owner']['name']}
													/>
												</div>
											)}{' '}
											<p className="tw-gap-[3px] tw-truncate tw-text-[9px] tw-leading-[1] tw-text-white lg:tw-text-[12px]">
												Made possible by <b>{data['owner']['name']}</b>
											</p>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="tw-flex tw-h-fit tw-min-h-[50px] tw-w-full tw-flex-row tw-items-center tw-justify-between tw-bg-[#0000002a] tw-px-[20px] lg:tw-min-h-[45px]">
							<div></div>{' '}
							{data['has_rewards'] ? (
								''
							) : (
								<>
									<div className="tw-flex tw-flex-row tw-items-center tw-gap-[13px]">
										<div className="tw-flex tw-h-full tw-w-fit tw-items-center">
											<span className="font-[500] tw-text-[12.3px] tw-leading-[1] tw-text-white">Rewards</span>
										</div>
										<div className="tw-relative tw-flex tw-max-h-[30.3px] tw-min-h-[30.3px] tw-min-w-[30.3px] tw-max-w-[30.3px] tw-items-center tw-justify-center tw-rounded-full tw-bg-[#00000033] tw-px-[7px]">
											<div className="tw-flex tw-h-fit tw-w-full">
												<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
													<path
														d="M15.4421 0.924675C15.3559 0.920107 15.2668 0.924045 15.1781 0.93737L2.29567 2.87348H16.7841L16.6571 2.03048C16.5639 1.40938 16.0459 0.95665 15.4421 0.924675ZM1.85766 4.17352C1.13939 4.17352 0.557617 4.7553 0.557617 5.47357V8.19301C1.31359 8.46147 1.85766 9.17611 1.85766 10.0237C1.85766 10.8714 1.31359 11.586 0.557617 11.8545V14.5739C0.557617 15.2922 1.13939 15.874 1.85766 15.874H16.1582C16.8765 15.874 17.4582 15.2922 17.4582 14.5739V11.8545C16.7023 11.586 16.1582 10.8714 16.1582 10.0237C16.1582 9.17611 16.7023 8.46147 17.4582 8.19301V5.47357C17.4582 4.7553 16.8765 4.17352 16.1582 4.17352H1.85766Z"
														fill="#A7A7A7"
													/>
													<path
														d="M15.4421 0.924675C15.3559 0.920107 15.2668 0.924045 15.1781 0.93737L2.29567 2.87348H16.7841L16.6571 2.03048C16.5639 1.40938 16.0459 0.95665 15.4421 0.924675ZM1.85766 4.17352C1.13939 4.17352 0.557617 4.7553 0.557617 5.47357V8.19301C1.31359 8.46147 1.85766 9.17611 1.85766 10.0237C1.85766 10.8714 1.31359 11.586 0.557617 11.8545V14.5739C0.557617 15.2922 1.13939 15.874 1.85766 15.874H16.1582C16.8765 15.874 17.4582 15.2922 17.4582 14.5739V11.8545C16.7023 11.586 16.1582 10.8714 16.1582 10.0237C16.1582 9.17611 16.7023 8.46147 17.4582 8.19301V5.47357C17.4582 4.7553 16.8765 4.17352 16.1582 4.17352H1.85766Z"
														fill="url(#paint0_linear_417_44312)"
													/>
													<defs>
														<linearGradient id="paint0_linear_417_44312" x1="0.557617" y1="0.922852" x2="17.6689" y2="19.8448" gradientUnits="userSpaceOnUse">
															<stop stopColor="white" />
															<stop offset="0.475" stopColor="white" stopOpacity="0" />
															<stop offset="1" stopColor="white" />
														</linearGradient>
													</defs>
												</svg>
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
		// </Link>
	)
}

export default CertificateListItemBig
