import dynamic from 'next/dynamic'

const Waypoint = dynamic(() => import('react-waypoint').then((component) => component.Waypoint), {
	ssr: false,
})

const Paginate = ({handlePrev = null, handleNext, loading, limit = 10, offset, setCurrentOffset = null, type = 'buttons', totalCountElements}) => {
	const hasNextPage = () => totalCountElements - limit * offset > 0

	const prev = () => {
		if (type === 'buttons') {
			setCurrentOffset(offset - 1)
		}

		handlePrev()
	}

	const next = () => {
		if (type === 'buttons') {
			setCurrentOffset(offset + 1)
		}

		handleNext()
	}

	const renderPaginate = () => {
		switch (type) {
			case 'buttons': {
				return <Buttons handlePrev={prev} handleNext={next} loading={loading} offset={offset} hasNextPage={!loading && hasNextPage()} />
			}

			case 'scroll': {
				return (
					<>
						{loading}
						<Scroll handleNext={next} hasNextPage={!loading && hasNextPage()} />
					</>
				)
			}
		}
	}

	return <>{renderPaginate()}</>
}

const Buttons = ({handlePrev, handleNext, loading, offset, hasNextPage}) => {
	return (
		<>
			{/* {offset} */}
			{/* {offset !== 0 && (
				<button disabled={loading} type="button" onClick={handlePrev}>
					prev
				</button>
			)} */}

			{hasNextPage && (
				<button disabled={loading} type="button" onClick={handleNext} className="ui-button ui-button-primary">
					Load More
				</button>
			)}
		</>
	)
}
Paginate.Buttons = Buttons

const Scroll = ({handleNext, hasNextPage}) => {
	return <>{hasNextPage && <Waypoint topOffset="-400px" onEnter={handleNext} />}</>
}
Paginate.Scroll = Scroll

export {Paginate}
