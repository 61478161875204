// Ключи делаем строками, т.к к одному коду ошибки может относиться две разные ошибки
//Переделать на коды ошибок, а не наименования
export const PaymentLocale = {
	errors: {
		'install-metamask': 'For authorization, you need to install the metamask extension in your browser',
		'metamask-account': 'Get metamask account error!',
		'denied-transaction': 'MetaMask Tx Signature: User denied transaction signature', // 4001
		'login-to-metamask': 'To continue, you need to log in to Metamask', // -32002
		initialization: 'WEB3 initialization error!',
		provider: 'WEB3 provider initialization error!',
		'no-access': 'No access to the blockchain!',
		'send-token': 'Send token WEB3 initialization error!',
		unexpected: 'Unexpected error! Please try again.',
		'insufficient-funds': 'Insufficient funds for transfer',
		'stripe-init': 'Stripe initialization error',
		'already-minted': 'Token is already minted',
	},
}

export const OtherLocale = {
	viewAll: 'View all',
}

export const UnitsLocale = {
	carbon: {
		icon: 'app-icon-units-carbon',
		short: 'tn',
		declension: ['tonne', 'tonnes', 'tonnes'],
		declension_short: ['tn', 'tn', 'tn'],
	},
	care: {
		icon: 'app-icon-units-care',
		short: 'caring',
		declension: ['caring', 'caring', 'caring'],
		declension_short: ['caring', 'caring', 'caring'],
	},
	enclosure: {
		icon: 'app-icon-units-enclosure',
		short: 'enclosure',
		declension: ['enclosure', 'enclosures', 'enclosures'],
		declension_short: ['enclosure', 'enclosures', 'enclosures'],
	},
	tree: {
		icon: 'app-icon-units-tree',
		short: 'tree',
		declension: ['tree', 'trees', 'trees'],
		declension_short: ['tree', 'trees', 'trees'],
	},
	care: {
		icon: 'app-icon-units-care',
		short: 'caring',
		declension: ['caring', 'caring', 'caring'],
		declension_short: ['caring', 'caring', 'caring'],
	},
	enclosure: {
		icon: 'app-icon-units-enclosure',
		short: 'enclosure',
		declension: ['enclosure', 'enclosures', 'enclosures'],
		declension_short: ['enclosure', 'enclosures', 'enclosures'],
	},
	donation: {
		icon: 'app-icon-units-donation',
		short: 'AUD',
		declension: ['AUD', 'AUD', 'AUD'],
		declension_short: ['AUD', 'AUD', 'AUD'],
	},
	grass: {
		icon: 'app-icon-units-grass',
		short: 'm2',
		declension: ['m2', 'm2', 'm2'],
		declension_short: ['m2', 'm2', 'm2'],
	},
	kelp: {
		icon: 'app-icon-units-kelp',
		short: 'kelp',
		declension: ['kelp', 'kelp', 'kelp'],
		declension_short: ['kelp', 'kelp', 'kelp'],
	},
	'burning-ha': {
		icon: 'app-icon-units-burning-ha',
		short: 'ha',
		declension: ['ha', 'ha', 'ha'],
		declension_short: ['ha', 'ha', 'ha'],
	},
	'fin-book': {
		icon: 'app-icon-units-fin-book',
		short: 'Fin Book',
		declension: ['Fin Book', 'Fin Books', 'Fin Books'],
		declension_short: ['Fin Book', 'Fin Books', 'Fin Books'],
	},
	'forever-wild-narndee': {
		icon: 'app-icon-units-forever-wild-narndee',
		short: 'ha',
		declension: ['ha', 'ha', 'ha'],
		declension_short: ['ha', 'ha', 'ha'],
	},
	'santa-visit': {
		icon: 'app-icon-units-santa-visit',
		short: 'Santa Visit',
		declension: ['Santa Visit', 'Santa Visits', 'Santa Visits'],
		declension_short: ['Santa Visit', 'Santa Visits', 'Santa Visits'],
	},
	'coral-star': {
		icon: 'app-icon-units-coral-star',
		short: 'coral star',
		declension: ['coral star', 'coral stars', 'coral stars'],
		declension_short: ['coral star', 'coral stars', 'coral stars'],
	},
	bird: {
		icon: 'app-icon-units-bird',
		short: 'bird',
		declension: ['bird', 'birds', 'birds'],
		declension_short: ['bird', 'birds', 'birds'],
	},
	book: {
		icon: 'app-icon-units-book',
		short: 'book',
		declension: ['book', 'books', 'books'],
		declension_short: ['book', 'books', 'books'],
	},
	equipment: {
		icon: 'app-icon-units-equipment',
		short: 'equipment',
		declension: ['equipment', 'equipment', 'equipment'],
		declension_short: ['equipment', 'equipment', 'equipment'],
	},
	survey: {
		icon: 'app-icon-units-survey',
		short: 'survey',
		declension: ['survey', 'surveys', 'surveys'],
		declension_short: ['survey', 'surveys', 'surveys'],
	},
	'adopt-a-coral': {
		icon: 'app-icon-units-adopt-a-coral',
		short: 'month',
		declension: ['month', 'months', 'months'],
		declension_short: ['month', 'months', 'months'],
	},
	'coral-expedition': {
		icon: 'app-icon-units-coral-expedition',
		short: 'coral expedition',
		declension: ['coral expedition', 'coral expeditions', 'coral expeditions'],
		declension_short: ['coral expedition', 'coral expeditions', 'coral expeditions'],
	},
	'rare-coral-expedition': {
		icon: 'app-icon-units-rare-coral-expedition',
		short: 'rare expedition',
		declension: ['rare expedition', 'rare expeditions', 'rare expeditions'],
		declension_short: ['rare expedition', 'rare expeditions', 'rare expeditions'],
	},
	'coral-health-check': {
		icon: 'app-icon-units-coral-health-check',
		short: 'coral H&M',
		declension: ['coral H&M', 'corals H&M', 'corals H&M'],
		declension_short: ['coral H&M', 'corals H&M', 'corals H&M'],
	},
	'adopt-a-spot': {
		icon: 'app-icon-units-adopt-a-spot',
		short: 'excursion',
		declension: ['excursion', 'excursions', 'excursions'],
		declension_short: ['excursion', 'excursions', 'excursions'],
	},
	'animal-rescue': {
		icon: 'app-icon-units-animal-rescue',
		short: 'animal treatment',
		declension: ['animal treatment', 'animal treatments', 'animal treatments'],
		declension_short: ['animal treatment', 'animal treatments', 'animal treatments'],
	},
	'airseed-seed-pod': {
		icon: 'app-icon-units-airseed-seed-pod',
		short: 'seed pod',
		declension: ['seed pod', 'seed pods', 'seed pods'],
		declension_short: ['seed pod', 'seed pods', 'seed pods'],
	},
	'coral-fragments': {
		icon: 'app-icon-units-coral-expedition',
		short: 'coral fragment',
		declension: ['coral fragment', 'coral fragments', 'coral fragments'],
		declension_short: ['coral fragment', 'coral fragments', 'coral fragments'],
	},
	'pound-of-plastic': {
		icon: 'app-icon-units-pound-of-plastic',
		short: 'lb of plastic',
		declension: ['lb of plastic', 'lbs of plastic', 'lbs of plastic'],
		declension_short: ['lb of plastic', 'lbs of plastic', 'lbs of plastic'],
	},
	'plastic-credit': {
		icon: 'app-icon-units-plastic-credit',
		short: 'kg of plastic',
		declension: ['kg of plastic', 'kg of plastic', 'kg of plastic'],
		declension_short: ['kg of plastic', 'kg of plastic', 'kg of plastic'],
	},
	'be-impact-units': {
		icon: 'app-icon-units-be-impact',
		short: 'ha',
		declension: ['ha', 'ha', 'ha'],
		declension_short: ['ha', 'ha', 'ha'],
	},
	'woodland-carbon2kg': {
		icon: 'app-icon-units-carbon',
		short: 'wc',
		declension: ['2kg of carbon', '2kg of carbon', '2kg of carbon'],
		declension_short: ['2kg of carbon', '2kg of carbon', '2kg of carbon'],
	},
	'woodland-carbon8kg': {
		icon: 'app-icon-units-carbon',
		short: 'wc',
		declension: ['8kg of carbon', '8kg of carbon', '8kg of carbon'],
		declension_short: ['8kg of carbon', '8kg of carbon', '8kg of carbon'],
	},
	'grassland-restoration520': {
		icon: 'app-icon-units-grass',
		short: 'gr',
		declension: ['520sqcm of Wild Land Restoration', '520sqcm of Wild Land Restoration', '520sqcm of Wild Land Restoration'],
		declension_short: ['520sqcm of Wild Land Restoration', '520sqcm of Wild Land Restoration', '520sqcm of Wild Land Restoration'],
	},
	'grassland-restoration2080': {
		icon: 'app-icon-units-grass',
		short: 'gr',
		declension: ['2080sqcm of Wild Land Restoration', '2080sqcm of Wild Land Restoration', '2080sqcm of Wild Land Restoration'],
		declension_short: ['2080sqcm of Wild Land Restoration', '2080sqcm of Wild Land Restoration', '2080sqcm of Wild Land Restoration'],
	},
	'everclime-club': {
		icon: 'app-icon-units-everclime-club',
		short: 'ec',
		declension: ['Everclime Club Pass', 'Everclime Club Passes', 'Everclime Club Passes'],
		declension_short: ['Club Pass', 'Club Passes', 'Club Passes'],
	}
}
