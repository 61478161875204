import {useRouter} from 'next/router'

import {Menu as MenuHeadless} from '@headlessui/react'
import {Float} from '@headlessui-float/react'
import {size} from '@floating-ui/react-dom'

import {Link} from '@ui/Modules'

//переделать menuClassName в будущем
const Menu = ({children, items = {}, className = '', menuClassName = '', ...props}) => {
	const router = useRouter()

	return (
		<MenuHeadless>
			<Float
				placement="bottom"
				offset={{mainAxis: 20}}
				strategy="absolute"
				flip
				enter="tw-transition tw-ease-out tw-duration-100"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-transition tw-ease-in tw-duration-100"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				middleware={() => [
					size({
						apply({availableWidth, availableHeight, elements}) {
							Object.assign(elements.floating.style, {
								maxWidth: availableWidth + 'px',
							})

							if (elements.floating.childNodes.length > 0) {
								elements.floating.childNodes[0].style.maxWidth = availableWidth + 'px'
							}
						},
					}),
				]}
			>
				<MenuHeadless.Button as="button" className={'ui-menu' + (className ? ' ' + className : '')} tabIndex="1" {...props}>
					{children}
				</MenuHeadless.Button>
				<MenuHeadless.Items as="ul" className={'ui-menu-items' + (menuClassName ? ' ' + menuClassName : '')}>
					{Object.entries(items).map(([title, slug]) => (
						<MenuHeadless.Item key={title} as="li">
							{({active}) => (
								<Link
									href={slug}
									itemProp="url"
									className={'ui-menu-item tw-flex tw-w-full tw-items-start tw-justify-between' + (router.pathname == slug || active ? ' ui-menu-item-active' : '')}
									tabIndex={1}
								>
									{/*TODO: не работает определение router.pathname c динамичным квери, а asPath пробрасывает лишние квери - переделать в будущем*/}
									{title}
								</Link>
							)}
						</MenuHeadless.Item>
					))}
				</MenuHeadless.Items>
			</Float>
		</MenuHeadless>
	)
}

export {Menu}
