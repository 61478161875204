import {useRouter} from 'next/router'

import {useAtom, useAtomValue} from 'jotai'
import {ModalState} from '@states/Modal'
import {UserState} from '@states/User'

import Search from '@chunks/_app/Search'

import {Icon} from '@ui/Modules'
import {useMemo} from 'react'

const MobileMenuModal = ({
	menu = [
		{
			title: 'Cards',
			slug: '/certificates',
		},
		{
			title: 'Projects',
			slug: '/projects',
		},
		{
			title: 'Brands',
			slug: '/brands',
		},
	],
	adminMenu = [
		{
			title: 'Pages',
			submenu: [
				{title: 'Certificates', slug: '/manager/certificates'},
				{title: 'Collections', slug: '/manager/collections'},
				{title: 'Projects', slug: '/manager/projects'},
				{title: 'Brands & Partners', slug: '/manager/brands'},
				{title: 'Posts', slug: '/manager/posts'},
				{title: 'Registries', slug: '/manager/registries'},
			],
		},
		{
			title: 'Shop',
			submenu: [
				{title: 'Orders', slug: '/manager/orders'},
				{title: 'Gifts', slug: '/manager/gifts'},
				{title: 'Rewards', slug: '/manager/rewards'},
				{title: 'Express Checkout', slug: '/manager/proposals'},
			],
			total: '0',
		},
		{
			title: 'Users',
			slug: '/manager/users',
		},
		{
			title: 'Requests',
			submenu: [
				{title: 'Tech support', slug: '/manager/support'},
				{title: 'Reports', slug: '/manager/reports'},
			],
		},
		{
			title: 'Other',
			submenu: [
				{title: 'Banners', slug: '/manager/banners'},
				{title: 'Lexicons', slug: '/manager/lexicons'},
			],
		},
		{
			title: 'System',
			submenu: [
				{title: 'API Keys', slug: '/manager/api/keys'},
				{title: 'Notifications', slug: '/manager/notifications'},
				{title: 'Logs', slug: '/manager/logs'},
			],
		},
	],
}) => {
	const [modal, setModal] = useAtom(ModalState)
	const user = useAtomValue(UserState)

	const router = useRouter()

	const handleMenuLink = (url) => {
		router.push(url).then(() => {
			setModal(false)
		})
	}

	const isOnManagerSite = useMemo(() => router.pathname.includes('/manager'), [router])

	//TODO: add loading state (and disable urls)

	return (
		<div className="ui-sidebar tw-relative tw-z-20 tw-flex tw-w-full tw-flex-shrink tw-flex-grow tw-flex-col tw-gap-4 tw-bg-slate-50">
			<div className="tw-flex tw-items-center tw-justify-between tw-gap-x-[32px] tw-py-[31px] tw-pl-[20px] tw-pr-[34px] lg:tw-py-[31px] lg:tw-pl-[40px] lg:tw-pr-[57px]">
				<a onClick={() => handleMenuLink('/')} className="tw-cursor-pointer">
					<img src="/assets/images/logo.svg" alt={process.env.NEXT_PUBLIC_APP_NAME} height="70px" className="tw-select-none" />
				</a>

				<button type="button" onClick={() => setModal(false)} className="tw-cursor-pointer">
					<Icon className="tw-h-[20px] tw-w-[20px] tw-text-on-surface-1 hover:tw-text-hover-primary" name="#app-icon-times" />
				</button>
			</div>
			<div className="tw-relative tw-flex-1">
				<div className="tw-absolute tw-inset-0 tw-overflow-y-auto tw-overflow-x-hidden">
					<div className="tw-flex tw-flex-col tw-gap-y-[36px] tw-px-[20px] tw-pb-[50px]">
						<ul className="tw-flex tw-select-none tw-flex-col tw-gap-y-8">
							{menu.map((item) => (
								<li key={item['title']}>
									{item['slug'] && (
										<a
											onClick={() => handleMenuLink(item['slug'])}
											className={'ui-h3 tw-cursor-pointer' + (router.pathname == item['slug'] ? ' tw-text-primary' : '')}
											tabIndex={1}
										>
											{item['title']}
										</a>
									)}
									{item['link'] && (
										<a href={item['link']} target="_blank" rel="noopener noreferrer" className="ui-h3" tabIndex={1}>
											{item['title']}
										</a>
									)}
								</li>
							))}
							{user &&
								user.admin &&
								isOnManagerSite &&
								adminMenu.map((item) => (
									<li key={item['title']}>
										{item['submenu'] && (
											<div>
												<span className="ui-h3"> {item.title}</span>
												<hr></hr>
												{/* <div className='tw-flex tw-flex-col tw-gap-1'> */}
												{item['submenu'].map((item) => (
													<li key={item['title']} className="tw-my-2">
														{item['slug'] && (
															<a
																onClick={() => handleMenuLink(item['slug'])}
																className={'ui-h3 tw-cursor-pointer' + (router.pathname == item['slug'] ? ' tw-text-primary' : '')}
																tabIndex={1}
															>
																{item['title']}
															</a>
														)}
														{item['link'] && (
															<a href={item['link']} target="_blank" rel="noopener noreferrer" className="ui-h3" tabIndex={1}>
																{item['title']}
															</a>
														)}
													</li>
												))}
												{/* </div> */}
											</div>
										)}

										{!item['submenu'] && (
											<>
												{item['slug'] && (
													<a
														onClick={() => handleMenuLink(item['slug'])}
														className={'ui-h3 tw-cursor-pointer' + (router.pathname == item['slug'] ? ' tw-text-primary' : '')}
														tabIndex={1}
													>
														{item['title']}
													</a>
												)}
												{item['link'] && (
													<a href={item['link']} target="_blank" rel="noopener noreferrer" className="ui-h3" tabIndex={1}>
														{item['title']}
													</a>
												)}
											</>
										)}
									</li>
								))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MobileMenuModal
