import {Fragment} from 'react'

import {Popover} from '@headlessui/react'
import {Float} from '@headlessui-float/react'
import {size} from '@floating-ui/react-dom'

//Костыльно перекрывается фиктивный button настоящим. В будущем подумать, как передавать клик с .button в состояние Popover

const Dropdown = ({className = '', ...props}) => {
	return (
		<span className={className + ' tw-relative'} {...props}>
			{props.children}
		</span>
	)
}

const Body = ({children, className = '', ...props}) => {
	return (
		<Popover as={Fragment}>
			{({open}) => (
				<Float
					placement="bottom"
					offset={{mainAxis: 12}}
					strategy="absolute"
					flip
					enter="tw-transition tw-ease-out tw-duration-100"
					enterFrom="tw-opacity-0"
					enterTo="tw-opacity-100"
					leave="tw-transition tw-ease-in tw-duration-100"
					leaveFrom="tw-opacity-100"
					leaveTo="tw-opacity-0"
					middleware={() => [
						size({
							apply({availableWidth, elements}) {
								Object.assign(elements.floating.style, {
									maxWidth: availableWidth + 'px',
								})
							},
						}),
					]}
				>
					<Popover.Button as="span" className="tw-absolute tw-inset-0 tw-z-20 tw-cursor-pointer"></Popover.Button>
					<Popover.Panel className={'tw-rounded-md tw-border tw-bg-white tw-text-surface-1 tw-shadow-small' + (className ? ' ' + className : '')} {...props}>
						{children}
					</Popover.Panel>
				</Float>
			)}
		</Popover>
	)
}

Dropdown.Body = Body

const Button = ({children, className = '', ...props}) => {
	return (
		<button type="button" className={className + ' tw-relative tw-z-10'} {...props}>
			{children}
		</button>
	)
}

Dropdown.Button = Button

export {Dropdown}
