import {useRouter} from 'next/router'
import {memo} from 'react'

import {amplitudeTrackEvent} from '@hooks/useAmplitude'

import {Icon} from '@ui/Modules'

//TODO: headlessui combobox

const Search = ({placeholder = 'Search', action = '/search', callback = null, className = ''}) => {
	const router = useRouter()

	const handleSubmit = (event) => {
		event.preventDefault()

		let data = new FormData(event.target)
		let values = Object.fromEntries(data.entries())

		amplitudeTrackEvent('SEARCH_STARTED', {
			keywords: values['q'],
		})

		router
			.push({
				pathname: action,
				query: {
					q: values['q'],
				},
			})
			.then(() => {
				if (callback) {
					callback()
				}
			})
	}

	return (
		<div itemScope itemType="http://schema.org/WebSite" className={className}>
			<link itemProp="url" href={process.env.NEXT_PUBLIC_APP_URL} />

			<form onSubmit={handleSubmit} action={action} itemProp="potentialAction" itemScope itemType="http://schema.org/SearchAction">
				<meta itemProp="target" content={action + '?q={q}'} />

				<div className="tw-relative">
					<button type="submit" className="tw-absolute tw-top-1/2 tw-left-[16px] tw-z-20 tw--mt-[10px] tw-text-surface-4">
						<Icon className="tw-h-[20px] tw-w-[20px]" name="#app-icon-search" />
					</button>

					<input
						type="text"
						placeholder={placeholder}
						defaultValue={router?.query?.q}
						itemProp="query"
						name="q"
						autoComplete="off"
						className="ui-input ui-input-primary tw-leading-[1.1] tw-max-h-[37.5px] tw-z-10 tw-w-full tw-max-w-[600px] !tw-pl-[46px]"
					/>
				</div>
			</form>
		</div>
	)
}

export default memo(Search)
