import dynamic from 'next/dynamic'
import {useAtom, useAtomValue} from 'jotai'
import {ModalState} from '@states/Modal'
import {UserState} from '@states/User'
import {ModalOverlay} from '@ui/Modules'
import MobileMenu from '@chunks/_app/modal/MobileMenu'
import {CardViewer} from '@chunks/_app/modal'
import {SelectedCardState} from '@states/SelectedCard'
import {useEffect} from 'react'
import {useRouter} from 'next/router'

const LoginModal = dynamic(() => import('@chunks/_app/modal/Login'), {
	ssr: false,
})
const EmailRequiredModal = dynamic(() => import('@chunks/_app/modal/EmailRequired'), {
	ssr: false,
})
const RegisterModal = dynamic(() => import('@chunks/_app/modal/Register'), {
	ssr: false,
})
const RegisterInlineModal = dynamic(() => import('@chunks/_app/modal/RegisterInline'), {
	ssr: false,
})
const ResetPasswordModal = dynamic(() => import('@chunks/_app/modal/ResetPassword'), {
	ssr: false,
})
const ResetPasswordInlineModal = dynamic(() => import('@chunks/_app/modal/ResetPasswordInline'), {
	ssr: false,
})
const MetamaskAuthModal = dynamic(() => import('@chunks/_app/modal/MetamaskAuth'), {
	ssr: false,
})
const SupportModal = dynamic(() => import('@chunks/_app/modal/Support'), {
	ssr: false,
})

const UserOnboardingModal = dynamic(() => import('@chunks/_app/modal/UserOnboarding'), {
	ssr: false,
})
const CarbonFootprintQuizModal = dynamic(() => import('@chunks/_app/modal/quiz/CarbonFootprint'), {
	ssr: false,
})

const AppModals = () => {
	const [modal, setModal] = useAtom(ModalState)
	const user = useAtomValue(UserState)
	const [slug, setSlug] = useAtom(SelectedCardState)
	const {query} = useRouter()

	useEffect(() => {
		if (!query['card-viewer-id']) return

		setSlug(query['card-viewer-id'])
		setModal('card-viewer')
	}, [query, setModal, setSlug])

	return (
		<>
			{modal === 'card-viewer' && (
				<ModalOverlay id="modal-card-carousel" isOpen setOpen={setModal} className="ui-overlay ui-overlay-primary tw-flex tw-h-full tw-w-full tw-p-0" closeOnClickOutside={false}>
					<CardViewer slug={slug} onClose={() => setModal(null)} />
				</ModalOverlay>
			)}

			{modal === 'mobile-menu' && (
				<ModalOverlay id="modal-mobile-menu" isOpen={modal === 'mobile-menu'} setOpen={setModal} className="ui-overlay ui-overlay-primary tw-p-0">
					<MobileMenu />
				</ModalOverlay>
			)}
			{modal === 'support' && (
				<ModalOverlay id="modal-support" isOpen={modal === 'support'} setOpen={setModal} className="ui-overlay ui-overlay-primary">
					<SupportModal />
				</ModalOverlay>
			)}

			{!user && (
				<>
					{modal === 'login' && (
						<ModalOverlay id="modal-login" isOpen={modal === 'login'} setOpen={setModal} className="ui-overlay-tertiary">
							<LoginModal />
						</ModalOverlay>
					)}
					{modal === 'register' && (
						<ModalOverlay id="modal-register" isOpen={modal === 'register'} setOpen={setModal} className="ui-overlay-tertiary">
							<RegisterModal />
						</ModalOverlay>
					)}
					{modal === 'register-inline' && (
						<ModalOverlay id="modal-register-inline" isOpen={modal === 'register-inline'} setOpen={setModal}>
							<RegisterInlineModal />
						</ModalOverlay>
					)}
					{modal === 'reset-password' && (
						<ModalOverlay id="modal-reset-password" isOpen={modal === 'reset-password'} setOpen={setModal} className="ui-overlay-tertiary">
							<ResetPasswordModal />
						</ModalOverlay>
					)}
					{modal === 'reset-password-inline' && (
						<ModalOverlay id="modal-reset-password-inline" isOpen={modal === 'reset-password-inline'} setOpen={setModal}>
							<ResetPasswordInlineModal />
						</ModalOverlay>
					)}
					{modal === 'metamask-auth' && (
						<ModalOverlay id="modal-metamask-auth" isOpen={modal === 'metamask-auth'} setOpen={setModal} className="ui-overlay-tertiary">
							<MetamaskAuthModal />
						</ModalOverlay>
					)}
				</>
			)}

			{user && (
				<>
					{modal === 'email-required' && (
						<ModalOverlay id="modal-email-required" isOpen={modal === 'email-required'} setOpen={setModal} className="ui-overlay ui-overlay-primary">
							<EmailRequiredModal />
						</ModalOverlay>
					)}

					{modal === 'user-onboarding' && (
						<ModalOverlay id="modal-user-onboarding" isOpen={modal === 'user-onboarding'} setOpen={setModal} className="ui-overlay ui-overlay-secondary">
							<UserOnboardingModal />
						</ModalOverlay>
					)}

					{modal === 'carbon-footprint-quiz' && (
						<ModalOverlay id="modal-carbon-footprint-quiz" isOpen={modal === 'carbon-footprint-quiz'} setOpen={setModal} className="ui-overlay ui-overlay-secondary">
							<CarbonFootprintQuizModal />
						</ModalOverlay>
					)}
				</>
			)}
		</>
	)
}

export default AppModals
