import {setCookie, parseCookies, serverCookies, destroyCookie} from '@hooks/useCookie'
import {dayjs} from '@hooks/useDate'

export const setCookies = (key, value, date = null, ctx = null) => {
	if (ctx) {
		serverCookies.set(ctx, key, value, {
			...(date && {expires: date}),
			path: '/',
		})
	} else {
		setCookie(null, key, value, {
			...(date && {expires: date}),
			path: '/',
		})
	}
}

export const removeCookies = (key, ctx = null, options = {}) => {
	if (ctx) {
		serverCookies.destroy(ctx, key, options)
	} else {
		destroyCookie(null, key, options)
	}
}

export const fetchWrapper = async (uri, ctx = null, options = {}) => {
	let response

	const cookies = ctx ? serverCookies.get(ctx) : parseCookies()
	const userCookies = ['access_token', 'access_token_expire_at', 'refresh_token', 'user.data']

	if (cookies['access_token'] && cookies['access_token_expire_at'] && cookies['refresh_token']) {
		if (dayjs(cookies['access_token_expire_at']).diff(dayjs(), 'second') < 60) {
			try {
				response = await fetch(process.env.NEXT_PUBLIC_API_URL + '/api/auth/refresh_tokens', {
					body: JSON.stringify({refresh_token: cookies['refresh_token']}),
					headers: {'Content-Type': 'application/json'},
					method: 'POST',
				})
			} catch (error) {
				userCookies.forEach((key) => removeCookies(key, ctx, {path: '/'}))

				if (typeof window !== 'undefined') {
					window.location.reload()
				}
			}

			if (response.ok) {
				const date = dayjs(response.headers.get('Expire-At')).toDate()

				const userCookies = {
					access_token: response.headers.get('Access-Token'),
					access_token_expire_at: response.headers.get('Expire-At'),
					refresh_token: response.headers.get('Refresh-Token'),
				}

				Object.entries(userCookies).forEach(([key, value]) => setCookies(key, value, date, ctx))
			} else {
				userCookies.forEach((key) => removeCookies(key, ctx, {path: '/'}))

				if (typeof window !== 'undefined') {
					window.location.reload()
				}
			}
		}
	}

	if ((!cookies['access_token'] || !cookies['access_token_expire_at'] || !cookies['refresh_token']) && cookies['user.data']) {
		userCookies.forEach((key) => removeCookies(key, ctx, {path: '/'}))

		if (typeof window !== 'undefined') {
			window.location.reload()
		}
	}

	options['credentials'] = 'include'

	if (!options['headers']) {
		options['headers'] = {}
	}

	if (cookies['access_token']) {
		options['headers']['Access-Token'] = cookies['access_token']
	}

	try {
		response = await fetch(uri, options)
	} catch (error) {
		console.error(error)
		response = false
	}

	return await responseWrapper(response)
}

export const fetchQuery = (params = {}) => {
	let query = ''

	Object.entries(params).forEach(([key, value]) => {
		query += (query ? '&' : '?') + key + '=' + value
	})

	return query
}

export const responseWrapper = async (response) => {
	let data

	try {
		data = await response.json()
	} catch (error) {
		data = {}
	}

	if (response && response.ok) {
		return {
			body: data,
			headers: response.headers,
			success: true,
		}
	} else {
		if (data?.error) {
			console.error(data.error)
		}

		return {
			body: {
				...data,
				...(!data?.error && {error: 'Something went wrong. Please, repeat later.'}),
			},
			headers: {},
			success: false,
		}
	}
}

export function declension(number, words) {
	const cases = [2, 0, 1, 1, 1, 2]

	number = parseInt(number)

	return words[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}

export function removeEmptyFields(data) {
	Object.keys(data).forEach((key) => {
		if (data[key] === '' || data[key] == null || data[key] === false) {
			delete data[key]
		}
	})
}

export function priceWithCurrency(price = 0, currency = 'usd') {
	switch (currency) {
		case 'matic':
			return [price.toLocaleString('en-US'), 'MATIC'].join(' ')

		case ('usd', 'aud'):
			return price.toLocaleString('en-US', {style: 'currency', currency: 'USD'}).replace('.00', '')

		default:
			return price.toLocaleString('en-US', {style: 'currency', currency: currency.toUpperCase()}).replace('.00', '')
	}
}

export function showCurrencyUnits(type) {
	let unit = ''

	switch (type) {
		case 'aud':
			unit = 'AUD'
			break

		case 'usd':
			unit = 'USD'
			break

		case 'gbp':
			unit = 'GBP'
			break

		case 'eur':
			unit = 'EUR'
			break
	}

	return unit
}

export const makeCoords = (coord, format = 'decimal') => {
	let result

	switch (format) {
		case 'degrees':
			result = Math.abs(coord).toFixed(2).replace('.', '°')

			break
		default:
			result = coord
	}

	return result
}

export const truncateString = (string = '', beforeSymbols = 0, afterSymbols = 0, soft = false) => {
	if (string.length > beforeSymbols + afterSymbols) {
		//Обрезаем до блжижайшего пробела
		if (soft) {
			let temporaryString
			let indexOfSpace

			if (beforeSymbols > 0) {
				temporaryString = string.slice(beforeSymbols)

				indexOfSpace = temporaryString.indexOf(' ')

				if (indexOfSpace > -1) {
					beforeSymbols += indexOfSpace
				} else {
					beforeSymbols += temporaryString.length - 1
				}
			}
			/*
			if (afterSymbols > 0) {
				//TODO	
			}
			*/
		}

		return (beforeSymbols > 0 ? string.substring(0, beforeSymbols) : '') + '...' + (afterSymbols > 0 ? string.substring(string.length - afterSymbols, string.length) : '')
	} else {
		return string
	}
}

export const stringToHashNumber = (input, min = 1, max = 10) => {
	let hash = 0
	for (var i = 0; i < input.length; i++) {
		hash = (hash << 5) - hash + input.charCodeAt(i)
	}

	return Math.floor((1 % Number('0.' + Math.abs(hash).toString())) * (max - min) + min)
}

export const existItemInCollection = (collection, id, key = 'id') => {
	return collection.map((item) => item[key]).includes(id)
}

export const capitalize = (str) => {
	try {
		return str[0].toUpperCase() + str.slice(1)
	} catch {
		return str
	}
}

export const cropNumber = (number, precision, hasEndingZero = false) => {
	let croppedNumber = number.toFixed(precision)

	if (!hasEndingZero) {
		croppedNumber = croppedNumber * 1
	}

	return croppedNumber
}

export const uniqId = () => new Date().getTime().toString(36).replace(/\D/gu, Math.random().toString()).replace(/\./gu, '').substring(0, 16)

export const stringWithSeparator = (words, separator = ', ') =>
	Array.isArray(words)
		? words
				.map((item) => item && String(item).trim())
				.filter((item) => item && item !== '')
				.join(separator)
		: words

export const makeLocation = (googleAddress) => {
	const location = googleAddress.reduce((acc, item) => {
		for (let i = 0; i < item['types'].length; i++) {
			switch (item['types'][i]) {
				case 'route': {
					acc['address'] = item['long_name']

					break
				}

				case 'street_number': {
					acc['street_number'] = item['long_name']

					break
				}

				case 'locality': {
					acc['city'] = item['long_name']

					break
				}

				case 'administrative_area_level_1': {
					acc['state'] = item['long_name']

					break
				}

				case 'country': {
					acc['country'] = item['long_name']

					break
				}
			}
		}

		return acc
	}, {})

	if (location['address'] && location['street_number']) {
		location['address'] = location['address'] + ' ' + location['street_number']
	}

	return location
}
