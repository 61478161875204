import {createContext, useCallback, useEffect, useMemo, useState} from 'react'
import {getBrandsRelatedCertificates} from '@actions/_app/certificate'

export const BrandRelatedCertificatesContext = createContext({
	certificates: {},
	brands: {},
	addBrands: (_brands = []) => {
		throw new Error('Not implemented')
	},
})

export function BrandRelatedCertificatesProvider({brands: initialBrands, children}) {
	const [brands, setBrands] = useState(initialBrands ?? [])
	const [certificates, setCertificates] = useState({})

	const refetchRelatedCertificates = async (brandSlugs) => {
		const response = await getBrandsRelatedCertificates(brandSlugs)
		return response.body.results.map(({slug, name, owner, style_properties, image_links}) => ({
			slug,
			name,
			style_properties,
			brand: owner?.slug,
			avatar: owner?.['avatar_links']?.['48x48'] ?? '/assets/images/pages/users/avatars/48x48.jpg',
			image: image_links?.['mobile_x1'],
		}))
	}

	useEffect(() => {
		// check if any new brands are added
		const brandsDiff = brands.filter((brand) => !(brand in certificates))
		if (!brandsDiff.length) return

		// fetch certificates only for the added brands and merge them with the rest
		refetchRelatedCertificates(brandsDiff).then((certs) => {
			const newCertificates = {}
			const newBrands = new Set(brands)

			for (const brand of brandsDiff) {
				newCertificates[brand] = []
			}
			for (const cert of certs) {
				// sometimes server returns certificates of brands other than those specified in filters
				if (!(cert.brand in newCertificates)) {
					newCertificates[cert.brand] = []
					newBrands.add(cert.brand)
				}
				newCertificates[cert.brand].push(cert)
			}

			setCertificates((prevCertificates) => ({...prevCertificates, ...newCertificates}))
			setBrands(Array.from(newBrands))
		})
	}, [brands, certificates])

	const addBrands = useCallback((newBrands) => {
		setBrands((prevBrands) => {
			const brands = new Set(prevBrands)

			for (const brand of newBrands) {
				brands.add(brand)
			}

			return Array.from(brands)
		})
	}, [])

	const value = useMemo(
		() => ({
			brands,
			certificates,
			addBrands,
		}),
		[addBrands, brands, certificates]
	)

	return <BrandRelatedCertificatesContext.Provider value={value}>{children}</BrandRelatedCertificatesContext.Provider>
}
